import React, { useEffect } from 'react';
import { Flex } from '../../../../components/Flex/Flex';
import { RoteiroPersonalizadoDatePicker } from '../RoteiroPersonalizadoDatePicker';
import { formatDateToPT } from '../../../../utils/date';
import { useRoteiro } from '../../../../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
import { RoteiroPersonalizadoSteps } from '../../../../interfaces/Fretamento/fretamento';
interface Props {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  handleStep: (step: RoteiroPersonalizadoSteps) => void;
}

export const RoteiroPersonalizadoData = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleStep
}: Props) => {
  const { dispatch } = useRoteiro();

  const ref = React.useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    if (startDate && endDate)
      dispatch({
        type: 'INICIALIZAR_ROTEIRO',
        payload: { startDate, endDate }
      });
    handleStep('dias');
  };

  useEffect(() => {
    if (startDate && endDate)
      ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [startDate, endDate]);

  return (
    <Flex className="flex-col px-5 mt-2 max-lg:pb-6">
      <Flex className="flex-col shadow-modalDatepicker rounded-b-[6px]">
        <Flex className="flex-col rounded-[6px] border-b-none rounded-b-[0px] border-[1px] border-neutral-pureOp10  w-full">
          <RoteiroPersonalizadoDatePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Flex>
        {startDate && endDate && (
          <Flex
            ref={ref}
            className="rounded-[6px] border-[1px] border-t-[0px] border-neutral-pureOp10 rounded-t-[0px] w-full p-4 justify-between items-center"
          >
            <p className="h6 text-neutral-pure700">
              {formatDateToPT(startDate)} - {formatDateToPT(endDate)}{' '}
            </p>
            <button
              onClick={handleSubmit}
              className="bg-[color:var(--bg-primary-pure)] rounded-[4px] h-10 text-white h6 px-6"
            >
              Selecionar
            </button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
