import React from 'react'
import { ptBR } from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Flex } from '../../../components/Flex/Flex';
import Icons from '../../../../public/assets/icons';
import { getMonth } from 'date-fns';

registerLocale('pt-BR', ptBR);
interface Props {
  startDate: Date | null
  endDate: Date | null
  setStartDate: (date: Date | null) => void
  setEndDate: (date: Date | null) => void
}

export const RoteiroPersonalizadoDatePicker = ({ startDate, endDate, setStartDate, setEndDate }: Props) => {

  return (
    <DatePicker
      locale={ptBR}
      open
      selected={startDate}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      minDate={new Date()}
      onChange={(dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      }}

      dateFormat={'Pp'}
      inline
      monthsShown={2}
      popperPlacement='bottom'

      calendarClassName={'w-[301px] datepicker bg-white p-2 '}
    />
  )
}
