import React, { useEffect, useState } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  Path,
} from "react-hook-form/dist/types";
import { Flex } from "../Flex/Flex";
interface Props<T extends FieldValues> {
  name: string;
  type?: string;
  placeholder?: string;
  className?: string;
  errors?: Record<string, unknown>;
  field?: ControllerRenderProps<T, Path<T>>;
  iconRight?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  value?: string;
  onClick?: () => void;
}
export const TextField = React.forwardRef<HTMLInputElement, Props<FieldValues>>(
  function TextField(
    {
      name,
      type = "text",
      placeholder,
      className,
      errors,
      field,
      icon,
      value,
      disabled = false,
      onClick,
      iconRight,
      ...props
    }: Props<FieldValues>,
    ref
  ) {
    const [isFocused, setIsFocused] = useState(false);

    const setRef = (inputElement: HTMLInputElement | null) => {
      if (typeof ref === "function") {
        ref(inputElement);
      } else if (ref) {
        ref.current = inputElement;
      }

      if (inputElement) {
        inputElement.addEventListener("focus", () => setIsFocused(true));
        inputElement.addEventListener("blur", () => setIsFocused(false));
      }
    };

    useEffect(() => {
      return () => {
        if (ref && typeof ref !== "function" && ref.current) {
          ref.current.removeEventListener("focus", () => setIsFocused(true));
          ref.current.removeEventListener("blur", () => setIsFocused(false));
        }
      };
    }, [ref]);
    return (
      <div className="relative">
        {icon && (
          <Flex
            className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ${isFocused && !errors?.[name] ? "text-[color:var(--text-primary-pure)]" : "text-neutral-pure600 "}`}
          >
            {icon}
          </Flex>
        )}
        <input
          id={name}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onClick={onClick}
          className={`${icon ? "pl-12" : "pl-3"} ${iconRight ? "pr-12" : "pr-3"} body h-14 input  rounded-[6px] ${errors?.[name]
            ? "border-alternative-error"
            : "border-neutral-pureOp10"
            } ${className}`}
          ref={setRef}
          {...field}
          {...props}
        />
        {iconRight && (
          <Flex
            className={`absolute inset-y-0 right-0 flex items-center pr-3  ${isFocused && !errors?.[name] ? "text-[color:var(--text-primary-pure)]" : "text-neutral-pure600 "}`}
          >
            {iconRight}
          </Flex>
        )}
      </div>
    );
  }
);
