import { AxiosError, AxiosResponse } from 'axios';
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation
} from 'react-query';
import { MutationError } from '../../../interfaces/Mutation';
import { api } from '../../axios';
import {
  AddressPoint,
  BuscaMetas,
  BuscaSplitMeta,
  Garage,
  TabOptions,
  Vehicle
} from '../../../interfaces/Fretamento/fretamento';

interface Route {
  stop_points: AddressPoint[];
  time: string;
  date: string;
  origin: AddressPoint;
  destination: AddressPoint;
}

export interface SimpleTrip {
  customerId: number;
  tripType: TabOptions;
  origin: AddressPoint;
  destination: AddressPoint;
  stop_points: AddressPoint[];
  vehicle_type: string;
  vehicle_usages: any[];
}

export interface CustomRouter {
  customer_id: number;
  hideDestination: boolean;
  step: number;
  disabledDates: {
    to: string;
  };
  tripDays: number;
  initialDate: string;
  endDate: string;
  days: {
    active: boolean;
    date: string;
    routes: Route[];
  }[];
  routes: Route[];
  status: boolean;
}

interface CalculateBody {
  customerId: number;
  simpleTrip?: SimpleTrip;
  customRouter?: CustomRouter;
}

export interface CalculateResponse {
  metas: BuscaMetas;
  driverExpenses: number;
  vehicles: Vehicle[];
  garage: Garage;
  resultsCount: number;
  metasSplit: {
    start_trip: BuscaSplitMeta;
    end_trip: BuscaSplitMeta;
    totalDistanceBoth: number;
    totalDurationBoth: number;
  };
}

export function useCalculate({
  options
}: {
  options?: UseMutationOptions<
    AxiosResponse<CalculateResponse>,
    AxiosError<MutationError>,
    CalculateBody,
    string[]
  >;
}): UseMutationResult<
  AxiosResponse<CalculateResponse>,
  AxiosError,
  CalculateBody,
  unknown
> {
  return useMutation(
    (data: CalculateBody) => api.post(`/calculate`, data),
    options
  );
}
