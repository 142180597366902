import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Flex } from '../Flex/Flex';
import { TextField } from './TextField';

export interface FormTextFieldProps<T extends FieldValues> {
  icon?: React.ReactNode;
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
  alwaysShowMask?: boolean;
  type?: string;
  errors: FieldErrors<T>;
  label?: string;
  className?: string;
  mask?: string;
  maskChar?: string | null;
}

interface MaskedInputProps {
  mask: string;
  maskChar?: string | null;
  alwaysShowMask: boolean;
  inputRef: React.Ref<HTMLInputElement>;
  children: React.ReactNode;
}

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  ({ children, inputRef, ...props }, ref) => {
    return (
      <InputMask {...props} inputRef={ref || inputRef}>
        {children}
      </InputMask>
    );
  }
);

MaskedInput.displayName = 'MaskedInput';

MaskedInput.displayName = 'MaskedInput';

const FormTextField = <T extends FieldValues>({
  control,
  name,
  placeholder,
  type = 'text',
  errors,
  label,
  className,
  mask,
  maskChar,
  alwaysShowMask = true,
  icon,
  ...props
}: FormTextFieldProps<T>) => {
  return (
    <Flex direction="col" className="gap-1 w-full p-1">
      {label && (
        <label
          htmlFor={name as string}
          className="bodysm text-neutral-pure1000 mb-1 "
        >
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const { ref, ...inputProps } = field;
          return mask ? (
            <MaskedInput
              mask={mask}
              maskChar={maskChar}
              alwaysShowMask={alwaysShowMask}
              {...inputProps}
              inputRef={ref}
            >
              <TextField
                icon={icon}
                name={name}
                type={type}
                placeholder={placeholder}
                className={` border-[1.5px] input border ${errors[name]
                  ? 'bg-white dark:bg-white border-alternative-error'
                  : 'bg-white dark:bg-white focus:border-[color:var(--border-primary-pure)] focus:shadow-[0px_0px_0px_3px_rgb(var(--shadow-selected)/0.05)] border-neutral-pureOp10'
                  } ${className}`}
                errors={errors}
                {...props}
              />
            </MaskedInput>
          ) : (
            <TextField
              {...inputProps}
              ref={ref}
              type={type}
              icon={icon}
              placeholder={placeholder}
              className={` border-[1.5px] input border ${errors[name]
                ? 'bg-white dark:bg-white border-alternative-error'
                : 'bg-white dark:bg-white focus:border-[color:var(--border-primary-pure)] focus:shadow-[0px_0px_0px_3px_rgb(var(--shadow-selected)/0.05)] border-neutral-pureOp10'
                } ${className}`}
              errors={errors}
              {...inputProps}
              {...props}
            />
          );
        }}
      />

      {errors[name] && (
        <span className="mt-1 text-xs text-red-500">
          {String(errors[name]?.message)}
        </span>
      )}
    </Flex>
  );
};

export default FormTextField;
