import { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../providers/AuthProvider/AuthContext';
import { Customer, User } from '../../interfaces/Auth/auth';
import { Tenant } from '../../interfaces/Tenant/tenant';

function useCheckAuth({ data }: { data?: Tenant }) {
  const [isLoading, setIsLoading] = useState(true);
  const { handleSetUser } = useContext(AuthContext);

  async function checkAuthentication() {
    setIsLoading(true);
    await axios
      .get(`/api/auth?tenant=${data?.name}`)
      .then(async (response: { data: { user: Customer } }) => {
        handleSetUser({ ...response.data.user });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.name]);

  return {
    isLoading
  };
}

export default useCheckAuth;
