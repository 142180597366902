import React from 'react';
import { Flex } from '../../../../components/Flex/Flex';
import FormTextField from '../../../../components/FormTextField/FormTextField';
import Icons from '../../../../../public/assets/icons';
import { useFretamento } from '../../../../utils/hooks/useFretamento';
import { useRoteiro } from '../../../../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
import { useBuscaContext } from '../../../../providers/BuscaProvider/BuscaProvider';

export const RoteiroPersonalizadoInfo = () => {
  const { handleShowModal } = useRoteiro();

  const { isLoading } = useBuscaContext();

  const { control, errors, handleSubmit, onSubmit } = useFretamento({
    buttonHandler: () => handleShowModal(false),
    customTab: 'customtrip'
  });

  return (
    <form
      className="w-full flex-col flex gap-6 h-full"
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <Flex className="justify-between h-full  pt-4 pb-8 flex-col gap-6 px-8">
        <Flex className="flex-col gap-4">
          <Flex className="flex-col lg:flex-row w-full gap-4 ">
            <FormTextField
              className="w-full bg-white"
              name="nome"
              label="Nome completo"
              placeholder="Seu nome"
              control={control}
              errors={errors}
              icon={<Icons.User className="w-[22px] h-[22px]" />}
            />
          </Flex>
          <Flex className="flex-col lg:flex-row w-full gap-4 ">
            <FormTextField
              className="w-full bg-white"
              name="email"
              label="E-mail"
              type="email"
              placeholder="Seu e-mail"
              control={control}
              errors={errors}
              icon={<Icons.Email className="w-[22px] h-[22px]" />}
            />
            <FormTextField
              className="w-full bg-white"
              name="telefone"
              label="Telefone"
              mask="(99) 99999-9999"
              control={control}
              errors={errors}
              icon={<Icons.Phone className="w-[22px] h-[22px]" />}
            />
          </Flex>
        </Flex>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full disabled:bg-neutral-pure300 h-14 px-6 bg-[color:var(--bg-primary-pure)] text-neutral-pure100 rounded-[6px]"
        >
          <Flex className="justify-center items-center gap-3">
            Criar roteiro
            <Icons.Search />
          </Flex>
        </button>
      </Flex>
    </form>
  );
};
