import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Customer } from '../../../interfaces/Auth/auth';
import { getUser } from '../../queries/users/getUser';
import { useToken } from '../../../utils/hooks/useToken';

export function useUser({
  options
}: {
  options?: UseQueryOptions<Customer, unknown, Customer, string[]>;
}): UseQueryResult<Customer> {
  const token = useToken();
  return useQuery(['user'], async () => getUser({ token }), options);
}
