// context/DataContext.js
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react';
import { Tenant } from '../../interfaces/Tenant/tenant';

const TenantContext = createContext<{
  data: Tenant | undefined;
  setData: Dispatch<SetStateAction<Tenant | undefined>>;
}>({
  data: undefined,
  setData: () => {}
});

interface Props {
  children: React.ReactNode;
}

export const TenantProvider = ({ children }: Props) => {
  const [data, setData] = useState<Tenant | undefined>();

  return (
    <TenantContext.Provider value={{ data, setData }}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenantDataProvider = () => useContext(TenantContext);
