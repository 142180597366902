import { Quote } from '../../../interfaces/Fretamento/fretamento';
import { api } from '../../axios';

interface Props {
  token?: string;
}

export const getQuotes = async ({
  token
}: Props): Promise<{ data: Quote[] }> => {
  const response = await api.get<{ data: Quote[] }>(`/user/quotes`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
};
