import React from 'react';
import { Flex } from '../../../../components/Flex/Flex';
import Icons from '../../../../../public/assets/icons';
import { format } from 'date-fns';
import {
  AddressPoint,
  Destino
} from '../../../../interfaces/Fretamento/fretamento';

interface OrigemProps {
  origem: AddressPoint;
  horarioSaída: string;
  pontosEmbarque?: (AddressPoint | undefined)[];
}

export const RoteiroPersonalizadoResumoOrigem = ({
  horarioSaída,
  origem,
  pontosEmbarque
}: OrigemProps) => {
  const isTimeFormat = (time: string) =>
    /^([01]\d|2[0-3]):([0-5]\d)$/.test(time);
  const formattedHorario = isTimeFormat(horarioSaída)
    ? horarioSaída
    : format(horarioSaída, 'HH:mm');
  return (
    <Flex className="w-full gap-4 min-h-14">
      <Flex direction="col" className="w-6 items-center min-w-6">
        <Flex className="w-6 h-6">
          <Icons.BlueBus className="text-[color:var(--text-primary-pure)] w-6 h-6" />
        </Flex>
        <div className="w-1 h-full bg-neutral-pure300 rounded-[10px]" />
      </Flex>
      <Flex className="w-full gap-2 flex-col mb-2">
        <p className="h6bold text-neutral-pure1000">{origem.address}</p>
        <Flex className="items-center gap-1.5 bodysm text-neutral-pure800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <g clipPath="url(#clip0_353_16442)">
              <path
                d="M2 8.5C2 9.28793 2.15519 10.0681 2.45672 10.7961C2.75825 11.5241 3.20021 12.1855 3.75736 12.7426C4.31451 13.2998 4.97595 13.7417 5.7039 14.0433C6.43185 14.3448 7.21207 14.5 8 14.5C8.78793 14.5 9.56815 14.3448 10.2961 14.0433C11.0241 13.7417 11.6855 13.2998 12.2426 12.7426C12.7998 12.1855 13.2417 11.5241 13.5433 10.7961C13.8448 10.0681 14 9.28793 14 8.5C14 7.71207 13.8448 6.93185 13.5433 6.2039C13.2417 5.47595 12.7998 4.81451 12.2426 4.25736C11.6855 3.70021 11.0241 3.25825 10.2961 2.95672C9.56815 2.65519 8.78793 2.5 8 2.5C7.21207 2.5 6.43185 2.65519 5.7039 2.95672C4.97595 3.25825 4.31451 3.70021 3.75736 4.25736C3.20021 4.81451 2.75825 5.47595 2.45672 6.2039C2.15519 6.93185 2 7.71207 2 8.5Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8.5L9.33333 10.5"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 5.16602V8.49935"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_353_16442">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <p className="text-neutral-pure800 font-[500]">
            Horário de saída: {formattedHorario}
          </p>
        </Flex>
        {pontosEmbarque?.map((ponto) => (
          <>
            {ponto?.address && (
              <Flex
                key={ponto.address}
                className="mb-1 items-center gap-1.5 bodysm text-neutral-pure800"
              >
                <Icons.Embarque className="w-4 h-4 text-[color:var(--text-primary-pure)]" />
                <p className="text-neutral-pure800 font-[600]">
                  {ponto.address}
                </p>
              </Flex>
            )}
          </>
        ))}
      </Flex>
    </Flex>
  );
};

interface DestinoProps {
  destino: AddressPoint;
  nextStop?: Destino;
}

export const RoteiroPersonalizadoResumoDestino = ({
  destino,
  nextStop
}: DestinoProps) => {
  const nextStopFormattedHorario = nextStop
    ? format(nextStop.horarioSaida, 'HH:mm')
    : '';

  return (
    <Flex className="w-full gap-4 min-h-[33px]">
      <Flex direction="col" className="w-6 items-center min-w-6">
        <Flex className="w-6 h-6">
          <Icons.BlueMapPin className="text-[color:var(--text-primary-pure)] w-6 h-6" />
        </Flex>
        <div className="w-1 h-full bg-neutral-pure300 rounded-[10px]" />
      </Flex>
      <Flex className="w-full gap-2 mb-2 flex-col">
        <p className="h6bold text-neutral-pure1000">{destino.address}</p>
        {nextStop && (
          <Flex className="w-full gap-1 flex-col">
            <Flex className="items-center gap-1.5 bodysm text-neutral-pure800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <g clipPath="url(#clip0_353_16442)">
                  <path
                    d="M2 8.5C2 9.28793 2.15519 10.0681 2.45672 10.7961C2.75825 11.5241 3.20021 12.1855 3.75736 12.7426C4.31451 13.2998 4.97595 13.7417 5.7039 14.0433C6.43185 14.3448 7.21207 14.5 8 14.5C8.78793 14.5 9.56815 14.3448 10.2961 14.0433C11.0241 13.7417 11.6855 13.2998 12.2426 12.7426C12.7998 12.1855 13.2417 11.5241 13.5433 10.7961C13.8448 10.0681 14 9.28793 14 8.5C14 7.71207 13.8448 6.93185 13.5433 6.2039C13.2417 5.47595 12.7998 4.81451 12.2426 4.25736C11.6855 3.70021 11.0241 3.25825 10.2961 2.95672C9.56815 2.65519 8.78793 2.5 8 2.5C7.21207 2.5 6.43185 2.65519 5.7039 2.95672C4.97595 3.25825 4.31451 3.70021 3.75736 4.25736C3.20021 4.81451 2.75825 5.47595 2.45672 6.2039C2.15519 6.93185 2 7.71207 2 8.5Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 8.5L9.33333 10.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 5.16602V8.49935"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_353_16442">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="text-neutral-pure800 font-[500]">
                Horário de saída: {nextStopFormattedHorario}
              </p>
            </Flex>
            {nextStop.pontosEmbarque?.map((ponto) => (
              <>
                {!!ponto?.address && (
                  <Flex
                    key={ponto?.address}
                    className="mb-1 items-center gap-1.5 bodysm text-neutral-pure800"
                  >
                    <Icons.Embarque className="w-4 h-4 text-[color:var(--text-primary-pure)]" />
                    <p className="text-neutral-pure800 font-[600]">
                      {ponto?.address}
                    </p>
                  </Flex>
                )}
              </>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
