import { AppProps } from 'next/app';
import 'react-data-grid/lib/styles.css';
import '../styles/globals.css';
import AuthProvider from '../providers/AuthProvider/AuthProvider';
import { CookieAuthenticator } from '../providers/CookieAuthenticator/CookieAuthenticator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { RoteiroProvider } from '../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TenantProvider } from '../providers/TenantProvider/TenantProvider';
import { OrcamentosProvider } from '../providers/OrcamentosProvider/OrcamentosProvider';
import { BuscaProvider } from '../providers/BuscaProvider/BuscaProvider';
import { FinalizarReservaProvider } from '../providers/FinalizarReservaProvider/FinalizarReservaProvider';

const queryClient = new QueryClient();
function MyApp({ Component, pageProps }: AppProps) {
  const AppComponent = Component as any;
  return (
    <QueryClientProvider client={queryClient}>
      <TenantProvider>
        <ToastContainer
          theme="light"
          limit={5}
          autoClose={10000}
          className="z-[999999]"
          style={{ zIndex: 999999 }}
        />

        <AuthProvider>
          <CookieAuthenticator>
            <BuscaProvider>
              <FinalizarReservaProvider>
                <RoteiroProvider>
                  <OrcamentosProvider>
                    <AppComponent {...pageProps} />
                  </OrcamentosProvider>
                </RoteiroProvider>
              </FinalizarReservaProvider>
            </BuscaProvider>
          </CookieAuthenticator>
        </AuthProvider>
      </TenantProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
