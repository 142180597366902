import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import useWindowWidth from '../../../utils/hooks/useWindowSize';
import { Flex } from '../../../components/Flex/Flex';
import Icons from '../../../../public/assets/icons';
import { RoteiroPersonalizadoResumo } from './Resumo/RoteiroPersonalizadoResumo';
import { RoteiroPersonalizadoSteps } from '../../../interfaces/Fretamento/fretamento';
import { RoteiroPersonalizadoData } from './Steps/RoteiroPersonalizadoData';
import { eachDayOfInterval } from 'date-fns';
import { RoteiroPersonalizadoDiasCarrosel } from './Steps/RoteiroPersonalizadoDiasCarrosel';
import useStateHandler from '../../../utils/hooks/useStateHandler';
import { RoteiroPersonalizadoDia } from './Steps/RoteiroPersonalizadoDia/RoteiroPersonalizadoDia';
import { RoteiroPersonalizadoInfo } from './Steps/RoteiroPersonalizadoInfo';

interface Props {
  showModal: boolean;
  handleClose: () => void;
}

const BlueCalendar: JSX.Element = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
  >
    <g clipPath="url(#clip0_353_15239)">
      <path
        d="M3.7998 7C3.7998 6.46957 3.99998 5.96086 4.3563 5.58579C4.71262 5.21071 5.19589 5 5.6998 5H17.0998C17.6037 5 18.087 5.21071 18.4433 5.58579C18.7996 5.96086 18.9998 6.46957 18.9998 7V19C18.9998 19.5304 18.7996 20.0391 18.4433 20.4142C18.087 20.7893 17.6037 21 17.0998 21H5.6998C5.19589 21 4.71262 20.7893 4.3563 20.4142C3.99998 20.0391 3.7998 19.5304 3.7998 19V7Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2002 3V7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.6001 3V7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.7998 11H18.9998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6499 14H6.6629"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50928 14H9.51528"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3594 14H12.3654"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2144 14H15.2204"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3643 17H12.3703"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65967 17H6.66467"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50928 17H9.51528"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_353_15239">
        <rect width="22.8" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const allDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  return eachDayOfInterval({ start: startDate, end: endDate });
};

export const RoteiroPersonalizadoModal = ({
  showModal,
  handleClose
}: Props) => {
  const width = useWindowWidth();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const startDateFormatted =
    startDate?.toLocaleDateString('pt-BR') || 'DD/MM/AAAA';
  const endDateFormatted = endDate?.toLocaleDateString('pt-BR') || 'DD/MM/AAAA';
  const [step, setStep] = useState<RoteiroPersonalizadoSteps>('data');
  const { state: selectedDate, handleState: handleSelectDate } =
    useStateHandler<Date | null>(startDate);

  const handleStep = (step: RoteiroPersonalizadoSteps) => {
    setStep(step);
  };

  const Step = (): JSX.Element => {
    switch (step) {
      case 'data':
        return (
          <RoteiroPersonalizadoData
            handleStep={handleStep}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        );
      case 'dias':
        return (
          <RoteiroPersonalizadoDia
            startDate={startDate}
            endDate={endDate}
            handleStep={handleStep}
            handleSelectDate={handleSelectDate}
            dia={selectedDate}
          />
        );
      case 'info':
        return <RoteiroPersonalizadoInfo />;
    }
  };

  useEffect(() => {
    if (startDate && endDate && !selectedDate) {
      handleSelectDate(startDate);
    }
  }),
    [startDate, endDate];

  useEffect(() => {
    if (startDate && endDate) {
      setStartDate(null);
      setEndDate(null);
      setStep('data');
    }
  }, [showModal]);

  const [mobileTab, setMobileTab] = useState<'rotas' | 'resumo' | 'info'>(
    'rotas'
  );

  return (
    <Modal
      animate={width < 1024}
      isOpen={showModal}
      handleClose={handleClose}
      shouldClose={false}
    >
      <Flex
        className=" w-full h-full overflow-y-auto  overflow-x-hidden lg:min-w-[944px] max-[1023px]:rounded-b-[0px] rounded-[12px] bg-white items-center z-[100]"
        direction="col"
      >
        <Flex className="flex-col gap-2 w-full max-lg:border-b-[1px] border-neutral-pureOp10 max-lg:pb-4">
          <Flex className="w-full px-6 justify-between  lg:border-b-[1px] border-neutral-pureOp10 items-center gap-4 max-lg:mt-8 lg:h-16">
            <p className="h4bold text-neutral-pure1000">
              Roteiro personalizado
            </p>
            <button onClick={handleClose}>
              <Icons.CloseModal className="w-10 h-10" />
            </button>
          </Flex>
          <Flex className="items-center gap-2 lg:hidden px-6">
            <Flex
              onClick={() => setMobileTab('rotas')}
              aria-selected={mobileTab === 'rotas'}
              className="rounded-[6px] justify-center items-center px-[14px] h-[38px] border-[1px] border-[color:var(--border-primary-pure)]Op10 aria-[selected=true]:border-[color:var(--border-primary-pure)] text-neutral-pureOp10 aria-[selected=true]:text-[color:var(--text-primary-pure)] cursor-pointer"
            >
              Rotas
            </Flex>
            <Flex
              onClick={() => setMobileTab('resumo')}
              aria-selected={mobileTab === 'resumo'}
              className="rounded-[6px] justify-center items-center px-[14px] h-[38px] border-[1px] border-[color:var(--border-primary-pure)]Op10 aria-[selected=true]:border-[color:var(--border-primary-pure)] text-neutral-pureOp10 aria-[selected=true]:text-[color:var(--text-primary-pure)] cursor-pointer"
            >
              Resumo do roteiro
            </Flex>
          </Flex>
        </Flex>
        <Flex className="w-full flex-col  overflow-y-auto lg:flex-row h-full lg:h-[667px]">
          {(width > 1023 || mobileTab === 'rotas') && (
            <Flex
              className="flex-col w-full lg:w-[648px] lg:min-w-[648px] min-h-full h-full gap-2 overflow-x-hidden"
              aria-id="datepicker"
            >
              <Flex className="flex-col gap-3 w-full border-b-[1px] px-5 border-neutral-pureOp10 ">
                <Flex
                  className={`${step === 'dias' ? 'pt-6 pb-3' : 'py-6'} flex-col gap-3`}
                >
                  <Flex className="gap-1 flex-col ">
                    <p className="h5 text-neutral-pure800">
                      Qual o período do seu roteiro?
                    </p>
                    <Flex className="w-full rounded-[6px] border-[1px] border-neutral-pureOp10">
                      <Flex className="text-[color:var(--text-primary-pure)] w-1/2 border-r-[1px] border-neutral-pureOp10 p-4 gap-2 items-center">
                        {BlueCalendar}
                        <p className="body text-neutral-pure600">
                          {startDateFormatted}
                        </p>
                      </Flex>
                      <Flex className="w-1/2  p-4 gap-2 items-center">
                        <p className="body text-neutral-pure600">
                          {endDateFormatted}
                        </p>
                      </Flex>
                    </Flex>
                  </Flex>
                  {step === 'dias' && selectedDate && (
                    <RoteiroPersonalizadoDiasCarrosel
                      selectedDate={selectedDate}
                      handleSelectDate={handleSelectDate}
                      datesInterval={allDatesBetween(startDate!, endDate!)}
                    />
                  )}
                </Flex>
              </Flex>
              {Step()}
            </Flex>
          )}
          {(width > 1023 || mobileTab === 'resumo') && (
            <RoteiroPersonalizadoResumo
              handleStep={() => {
                handleStep('data');
                setMobileTab('rotas');
              }}
              endDate={endDate}
              startDate={startDate}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
