import { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult
} from 'react-query';
import { MutationError } from '../../../interfaces/Mutation';
import { api } from '../../axios';
import {
  Coordinates,
  DayUse,
  DriverExpensesData,
  Garage,
  MapsData,
  OriginalPointsDistance,
  Seasonalities,
  TripDates,
  Vehicle
} from '../../../interfaces/Fretamento/fretamento';
import { CustomRouter } from '../orders/useCalculate';
import { useToken } from '../../../utils/hooks/useToken';

export interface SaveSearchMetas {
  driverExpenses: number;
  garage: number;
  tripDates: TripDates; // Object for trip date information
  mapsData: MapsData; // Object for map-related data
  garagePoint: Coordinates; // Object for garage location coordinates
  garageDetails: Garage; // Object for garage details
  oneWayDistance: number;
  customToFinalGarage: number;
  originalPointsDistance: OriginalPointsDistance; // Object for original points distance details
  distanceBetweenGarageAndOrigin: number;
  hoursBetweenGarageAndOrigin: number;
  totalDistance: number;
  totalDuration: number;
  securityMargin: number;
  totalDistanceAndSecurity: number;
  additionalMileage: number;
  dayUse: DayUse; // Object for day-use information
  driverExpensesData: DriverExpensesData; // Object for driver expenses details
  seasonalities: Seasonalities; // Object for seasonality data
}

export interface ExtraData {
  trip?: CustomRouter;
  metas: SaveSearchMetas;
  driverExpenses: number;
}

export interface SaveSearchBody {
  customer_id: number; // Unique ID for the customer (integer)
  district_origin: string; // Origin district
  route_origin: string; // Origin street address
  city_origin: string; // Origin city
  state_origin: string; // Origin state
  date_origin: string; // Origin date (YYYY/MM/DD format)

  district_destination: string; // Destination district
  route_destination: string; // Destination street address
  city_destination: string; // Destination city
  state_destination: string; // Destination state
  date_destination: string; // Destination date (YYYY/MM/DD format)

  type: string; // Trip type (e.g., "custom", "business")
  extra_data: ExtraData; // Object for additional trip details
  vehicles: Vehicle[]; // Array of vehicles used in the trip
}

export interface SearchResponseData {
  customer_id: number; // Unique ID for the customer
  city_origin: string; // Origin city
  district_origin: string; // Origin district
  state_origin: string; // Origin state
  date_origin: string; // Origin date (YYYY/MM/DD format)

  city_destination: string; // Destination city
  district_destination: string; // Destination district
  state_destination: string; // Destination state
  date_destination: string; // Destination date (YYYY/MM/DD format)

  type: string; // Trip type (e.g., "custom")
  extra_data: string | null; // Extra trip data (nullable string)
  funnel_stage_id: number; // Funnel stage ID

  user_id: string | null; // User ID (nullable)
  kanban_card_updated_at: string; // Date and time of Kanban card update (ISO 8601 format)
  origin_point_id: number; // Origin point ID

  service_start: string; // Service start date (YYYY/MM/DD format)
  uuid: string; // Unique identifier (UUID format)
  updated_at: string; // Date and time of update (ISO 8601 format)
  created_at: string; // Date and time of creation (ISO 8601 format)
  id: number; // Unique ID of this trip data entry
}

export function useSaveSearch({
  options
}: {
  options?: UseMutationOptions<
    AxiosResponse<SearchResponseData>,
    AxiosError<MutationError>,
    SaveSearchBody,
    string[]
  >;
}): UseMutationResult<
  AxiosResponse<SearchResponseData>,
  AxiosError,
  SaveSearchBody,
  unknown
> {
  const token = useToken();
  return useMutation(
    (data: SaveSearchBody) =>
      api.post(`/search/save`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
    options
  );
}
