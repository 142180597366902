import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Quote } from '../../../interfaces/Fretamento/fretamento';
import { useToken } from '../../../utils/hooks/useToken';
import { getQuotes } from '../../queries/quotes/getQuotes';

export function useQuotes({
  options
}: {
  options?: UseQueryOptions<
    { data: Quote[] },
    unknown,
    { data: Quote[] },
    string[]
  >;
}): UseQueryResult<{ data: Quote[] }> {
  const token = useToken();
  return useQuery(['quotes'], async () => getQuotes({ token }), options);
}
