import jwt from 'jsonwebtoken';
import { CookieUser, User } from '../interfaces/Auth/auth';

export const decodeToken = (token: string): CookieUser | null => {
  try {
    const decoded = jwt.decode(token);

    if (typeof decoded === 'object' && decoded !== null) {
      return decoded as unknown as CookieUser;
    }

    return null;
  } catch (error) {
    console.error('Failed to decode token', error);
    return null;
  }
};

export const authenticateUserServerSide = (
  tenantName: string,
  cookies: Partial<{
    [key: string]: string;
  }>
): User | null => {
  const user = cookies[`@${tenantName}/user`];
  try {
    if (!user) {
      return null;
    }
    return user ? JSON.parse(user) : null;
  } catch (err) {
    return null;
  }
};
