import withIconStyles from './wrapper';

import Delete from './delete.svg?svgr';
import Heart from './heart.svg?svgr';
import Hash from './hash.svg?svgr';
import ArrowDown from './arrow-down.svg?svgr';
import ArrowRight from './arrow-right.svg?svgr';
import Bus from './bus.svg?svgr';
import ChevronDown from './chevron-down.svg?svgr';
import ChevronRight from './chevron-right.svg?svgr';
import CircleArrowLeft from './circle-arrow-left.svg?svgr';
import CircleCheck from './circle-check.svg?svgr';
import CirclePlus from './circle-plus.svg?svgr';
import Done from './done.svg?svgr';
import Download from './download.svg?svgr';
import Email from './email.svg?svgr';
import Phone from './phone.svg?svgr';
import Table from './table.svg?svgr';
import User from './user.svg?svgr';
import CircleBuilding from './circle-building.svg?svgr';
import CircleUser from './circle-user.svg?svgr';
import BlueChevronUp from './blue-chevron-up.svg?svgr';
import BluePhone from './blue-phone.svg?svgr';
import Whatsapp from './whatsapp.svg?svgr';
import BlueEmail from './blue-email.svg?svgr';
import CircleTwitter from './circle-twitter.svg?svgr';
import CircleFacebook from './circle-facebook.svg?svgr';
import CircleInstagram from './circle-instagram.svg?svgr';
import CircleYoutube from './circle-youtube.svg?svgr';
import CircleLinkedin from './circle-linkedin.svg?svgr';
import Password from './password.svg?svgr';
import Eye from './eye.svg?svgr';
import CadastroFinalizado from './cadastro-finalizado.svg?svgr';
import RecuperarSenha from './recuperar-senha.svg?svgr';
import YellowEmail from './yellow-email.svg?svgr';
import YellowLock from './yellow-lock.svg?svgr';
import Home from './home.svg?svgr';
import PurpleUser from './purple-user.svg?svgr';
import PurplePin from './purple-pin.svg?svgr';
import PurpleHeart from './purple-heart.svg?svgr';
import PurpleLock from './purple-lock.svg?svgr';
import RedLogout from './red-logout.svg?svgr';
import Wave from './wave.svg?svgr';
import BusPin from './bus-pin.svg?svgr';
import SelectedEnterprise from './selected-enterprise.svg?svgr';
import Burger from './burger.svg?svgr';
import Divider from './divider.svg?svgr';
import Pen from './pen.svg?svgr';
import Camera from './camera.svg?svgr';
import Close from './close.svg?svgr';
import BigCamera from './big-camera.svg?svgr';
import Orçamentos from './orcamentos.svg?svgr';
import PurpleLockSmall from './purple-lock-small.svg?svgr';
import RecuperarSenhaSmall from './recuperar-senha-small.svg?svgr';
import Paid from './paid.svg?svgr';
import AwaitingPayment from './awaiting-payment.svg?svgr';
import LeftBlueArrow from './left-blue-arrow.svg?svgr';
import CreditCard from './credit-card.svg?svgr';
import BankSlip from './bank-slip.svg?svgr';
import PIX from './pix.svg?svgr';
import DespesasTick from './despesas-tick.svg?svgr';
import CloseModal from './close-modal.svg?svgr';
import BlueAdd from './blue-add.svg?svgr';
import BlueBusSmall from './blue-bus-small.svg?svgr';
import BlueBus from './blue-bus.svg?svgr';
import BlueCalendarSmall from './blue-calendar-small.svg?svgr';
import BlueMallet from './blue-mallet.svg?svgr';
import BlueMapPinSmall from './blue-map-pin-small.svg?svgr';
import BlueMapPin from './blue-map-pin.svg?svgr';
import BluePerson from './blue-person.svg?svgr';
import RedoSearch from './redo-search.svg?svgr';
import Veiculos from './veiculos.svg?svgr';
import Minus from './minus.svg?svgr';
import Plus from './plus.svg?svgr';
import Ar from './ar.svg?svgr';
import Banheiro from './banheiro.svg?svgr';
import Wifi from './wifi.svg?svgr';
import Rotas from './rotas.svg?svgr';
import Tv from './tv.svg?svgr';
import GreenCheck from './green-check.svg?svgr';
import MotoristaNao from './motorista-nao.svg?svgr';
import MotoristaSim from './motorista-sim.svg?svgr';
import MotoristaSimSmall from './motorista-sim-small.svg?svgr';
import BlueInfo from './blue-info.svg?svgr';
import ResumoDolar from './resumo-dolar.svg?svgr';
import ResumoReserva from './resumo-reserva.svg?svgr';
import ResumoFinalizado from './resumo-finalizado.svg?svgr';
import ResumoShield from './resumo-shield.svg?svgr';
import CirclePhone from './circle-phone.svg?svgr';
import Coupon from './coupon.svg?svgr';
import CircleChevron from './circle-chevron.svg?svgr';
import CircleDolar from './circle-dolar.svg?svgr';
import Visa from './visa.svg?svgr';
import Mastercard from './mastercard.svg?svgr';
import Elo from './elo.svg?svgr';
import Hipercard from './hipercard.svg?svgr';
import Printer from './printer.svg?svgr';
import BankSlipCalendar from './bank-slip-calendar.svg?svgr';
import BankSlipAnthen from './bank-slip-anthen.svg?svgr';
import Qrcode from './qrcode.svg?svgr';
import ReservaFinalizada from './reserva-finalizada.svg?svgr';
import ReservaFinalizadaSmall from './reserva-finalizada-small.svg?svgr';
import Copy from './copy.svg?svgr';
import Regras from './regras.svg?svgr';
import CircleBus from './circle-bus.svg?svgr';
import CircleClose from './circle-close.svg?svgr';
import Logout from './logout.svg?svgr';
import MotoristaSimMedium from './motorista-sim-medium.svg?svgr';
import MotoristaNaoMedium from './motorista-nao-medium.svg?svgr';
import Filter from './filter.svg?svgr';
import Chat from './chat.svg?svgr';
import LargePhone from './large-phone.svg?svgr';
import BlueCircleMinus from './blue-circle-minus.svg?svgr';
import LargeWhatsapp from './large-whatsapp.svg?svgr';
import LargeCirclePlus from './large-circle-plus.svg?svgr';
import RemoveEmbarque from './remove-embarque.svg?svgr';
import RemoveEmbarqueSmall from './remove-embarque-small.svg?svgr';
import Search from './search.svg?svgr';
import Clock from './clock.svg?svgr';
import ClosedEye from './closed-eye.svg?svgr';
import OpenEye from './open-eye.svg?svgr';
import CircleChevronLeft from './circle-chevron-left.svg?svgr';
import HeaderPurpleUser from './header-purple-user.svg?svgr';
import Embarque from './embarque.svg?svgr';

const rawIcons = {
  Embarque,
  HeaderPurpleUser,
  CircleChevronLeft,
  OpenEye,
  ClosedEye,
  Clock,
  Search,
  RemoveEmbarqueSmall,
  RemoveEmbarque,
  LargeCirclePlus,
  BlueCircleMinus,
  LargeWhatsapp,
  LargePhone,
  Chat,
  Filter,
  MotoristaNaoMedium,
  MotoristaSimMedium,
  Logout,
  CircleClose,
  CircleBus,
  Regras,
  Copy,
  ReservaFinalizadaSmall,
  ReservaFinalizada,
  Qrcode,
  BankSlipAnthen,
  BankSlipCalendar,
  Printer,
  Hipercard,
  Elo,
  Mastercard,
  Visa,
  CircleDolar,
  CircleChevron,
  Coupon,
  CirclePhone,
  ResumoShield,
  ResumoFinalizado,
  ResumoReserva,
  ResumoDolar,
  MotoristaSimSmall,
  BlueInfo,
  MotoristaSim,
  MotoristaNao,
  GreenCheck,
  Tv,
  Rotas,
  Ar,
  Banheiro,
  Wifi,
  Minus,
  Plus,
  Veiculos,
  RedoSearch,
  BlueMapPin,
  BluePerson,
  BlueMapPinSmall,
  BlueMallet,
  BlueCalendarSmall,
  BlueBus,
  BlueBusSmall,
  BlueAdd,
  CloseModal,
  DespesasTick,
  PIX,
  BankSlip,
  CreditCard,
  LeftBlueArrow,
  AwaitingPayment,
  Paid,
  RecuperarSenhaSmall,
  PurpleLockSmall,
  Orçamentos,
  BigCamera,
  Close,
  Camera,
  Pen,
  Divider,
  Burger,
  SelectedEnterprise,
  BusPin,
  Wave,
  RedLogout,
  PurpleLock,
  PurpleHeart,
  PurplePin,
  PurpleUser,
  Home,
  YellowLock,
  YellowEmail,
  RecuperarSenha,
  CadastroFinalizado,
  Eye,
  Password,
  CircleLinkedin,
  CircleYoutube,
  CircleInstagram,
  CircleFacebook,
  CircleTwitter,
  BlueEmail,
  Whatsapp,
  BluePhone,
  BlueChevronUp,
  CircleUser,
  CircleBuilding,
  Delete,
  Heart,
  Hash,
  ArrowDown,
  ArrowRight,
  Bus,
  ChevronDown,
  ChevronRight,
  CircleArrowLeft,
  CircleCheck,
  CirclePlus,
  Done,
  Download,
  Email,
  Phone,
  Table,
  User
};

const Icons = Object.fromEntries(
  Object.entries(rawIcons).map(([key, IconComponent]) => {
    return [key, withIconStyles(IconComponent)];
  })
);

export default Icons;
