import { array, boolean, number, object, string } from 'yup';

const destinoSchema = object({
  index: number(), // Field to hold the index, not actually used for validation
  horarioSaida: string().required('Horário de saída é obrigatório'),
  lugarSaida: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).when('index', ([index], schema) => {
    return index === 0
      ? schema.required('Lugar de saída é obrigatório')
      : schema;
  }),
  lugarDestino: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  pontosEmbarque: array()
    .of(
      object({
        address: string().required(
          'É obrigatório inserir um endereço formatado.'
        ),
        city: string().required('É obrigatório inserir uma cidade.'),
        address_components: object({
          route: string().required('É obrigatório inserir um endereço.'),
          state: string().required('É obrigatório inserir um estado.'),
          state_short: string().required('É obrigatório inserir um estado.'),
          city: string().required('É obrigatório inserir uma cidade.'),

          postal_code: string()
        }),
        lat: string().required('É obrigatório inserir a latitude.'),
        lng: string().required('É obrigatório inserir a longitude.')
      })
    )
    .nullable()
});

export const schema = object({
  destinos: array().of(destinoSchema),
  vaiUsarVeiculoNoDia: boolean()
});
