import React, { createContext, useContext, ReactNode } from 'react';

import useStateHandler from '../../utils/hooks/useStateHandler';

import { CalculateResponse } from '../../http/mutations/orders/useCalculate';
import { TravelDetails } from '../../interfaces/Fretamento/fretamento';

interface BuscaState {
  buscaResult: CalculateResponse | null;
  handleSetBuscaResult: (buscaResult: CalculateResponse) => void;
  isLoading: boolean;
  handleIsLoading: (isLoading: boolean) => void;
  dePara: DePara;
  handleDePara: (dePara: DePara) => void;
  busca: TravelDetails;
  handleBusca: (busca: TravelDetails) => void;
}

type BuscaContextType = BuscaState;

const BuscaContext = createContext<BuscaContextType | undefined>(undefined);

type BuscaProviderProps = {
  children: ReactNode;
};

export type DePara = {
  de: string;
  para: string;
};

export const BuscaProvider = ({ children }: BuscaProviderProps) => {
  const { state: buscaResult, handleState: handleSetBuscaResult } =
    useStateHandler<CalculateResponse | null>(null);

  const { state: dePara, handleState: handleDePara } = useStateHandler<DePara>({
    de: '',
    para: ''
  });
  const { state: isLoading, handleState: handleIsLoading } =
    useStateHandler<boolean>(false);

  const { state: busca, handleState: handleBusca } =
    useStateHandler<TravelDetails>({
      type: 'roundtrip',
      dataIda: '',
      dataVolta: '',
      embarques: [],
      destino: {
        address: '',
        address_components: {
          route: '',
          state: '',
          state_short: '',
          city: '',
          district: '',
          postal_code: ''
        },
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: ''
      },
      origem: {
        address: '',
        address_components: {
          route: '',
          state: '',
          state_short: '',
          city: '',
          district: '',
          postal_code: ''
        },
        city: '',
        lat: 0,
        lng: 0,
        date: '',
        time: ''
      }
    });

  return (
    <BuscaContext.Provider
      value={{
        buscaResult,
        handleSetBuscaResult,
        isLoading,
        handleIsLoading,
        dePara,
        handleDePara,
        busca,
        handleBusca
      }}
    >
      {children}
    </BuscaContext.Provider>
  );
};

export const useBuscaContext = () => {
  const context = useContext(BuscaContext);
  if (context === undefined) {
    throw new Error(
      'useBuscaContext deve ser usado dentro de um BuscaProvider'
    );
  }
  return context;
};
