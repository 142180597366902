import { Dia } from '../interfaces/Fretamento/fretamento';

export function formatDateToPT(dateInput: string | Date): string {
  const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ];

  const date = new Date(dateInput);
  const dayOfWeek = weekDays[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];

  return `${dayOfWeek}, ${dayOfMonth} ${month}.`;
}

export function generateDateRange(startDate: Date, endDate: Date): Dia[] {
  let currentDate = new Date(startDate.getTime());
  const intervaloDias: Dia[] = [];

  while (currentDate <= endDate) {
    intervaloDias.push({
      data: currentDate.toLocaleDateString('pt-BR'), // Convert to ISO string for consistency
      destinos: [],
      vaiUsarVeiculoNoDia: false
    });
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  return intervaloDias;
}
