import { createContext } from 'react';
import { Customer } from '../../interfaces/Auth/auth';
interface AuthContextType {
  user?: Customer | null;
  selectedCompany?: string;
  handleSelectCompany: ({ value }: { value: string }) => void;
  handleLogin: ({
    token,
    user
  }: {
    token: string;
    user: Customer;
  }) => Promise<void>;
  logout: () => void;
  handleSetUser: (user: Customer) => void;
  refetchUserQuery: () => void;
  isLoading: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  refetchUserQuery: () => {},
  selectedCompany: '',
  handleSelectCompany: () => {},
  user: null,
  handleLogin: () => new Promise(() => {}),
  logout: () => {},
  handleSetUser: () => {},
  isLoading: false
});
