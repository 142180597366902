import { AxiosError, AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult
} from 'react-query';
import { MutationError } from '../../interfaces/Mutation';
import { api } from '../axios';

interface LeadRegisterBody {
  name: string;
  email: string;
  tel: string;
}

export function useMutateLeadRegister({
  options
}: {
  options?: UseMutationOptions<
    AxiosResponse,
    AxiosError<MutationError>,
    LeadRegisterBody,
    string[]
  >;
}): UseMutationResult<AxiosResponse, AxiosError, LeadRegisterBody, unknown> {
  return useMutation(
    (data: LeadRegisterBody) => api.post(`/lead/register`, data),
    options
  );
}
