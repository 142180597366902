import React, { createContext, useContext, ReactNode } from 'react';
import { Quote } from '../../interfaces/Fretamento/fretamento';
import useStateHandler from '../../utils/hooks/useStateHandler';
import { useQuotes } from '../../http/hooks/quotes/useQuotes';
import { useAuth } from '../AuthProvider/AuthProvider';

interface OrcamentoState {
  orcamentos?: Quote[] | null;
  isLoading: boolean;
}

type OrcamentoContextType = OrcamentoState;

const OrcamentoContext = createContext<OrcamentoContextType | undefined>(
  undefined
);

type OrcamentosProviderProps = {
  children: ReactNode;
};

export const OrcamentosProvider = ({ children }: OrcamentosProviderProps) => {
  const { user } = useAuth();

  const { data, isLoading } = useQuotes({
    options: {
      enabled: !!user,
      refetchOnWindowFocus: false
    }
  });

  return (
    <OrcamentoContext.Provider value={{ orcamentos: data?.data, isLoading }}>
      {children}
    </OrcamentoContext.Provider>
  );
};

export const useOrcamentos = () => {
  const context = useContext(OrcamentoContext);
  if (context === undefined) {
    throw new Error(
      'useOrcamentos deve ser usado dentro de um OrcamentoProvider'
    );
  }
  return context;
};
