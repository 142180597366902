import React, { useReducer, ReactNode, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { useRouter } from 'next/router';
import { Customer, User } from '../../interfaces/Auth/auth';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { decodeToken } from '../../utils/auth';
import { useTenantDataProvider } from '../TenantProvider/TenantProvider';
import { useUser } from '../../http/hooks/users/useUser';
import { useToken } from '../../utils/hooks/useToken';
import { addDays } from 'date-fns';

interface AuthProviderProps {
  children: ReactNode;
}

interface State {
  user?: Customer | null;
  selectedCompany: string;
}

type Action =
  | { type: 'SET_USER'; payload?: Customer | null }
  | { type: 'SET_SELECTED_COMPANY'; payload: string };

const initialState = {
  user: undefined as Customer | undefined,
  selectedCompany: ''
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_SELECTED_COMPANY':
      return { ...state, selectedCompany: action.payload };
    default:
      return state;
  }
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data } = useTenantDataProvider();

  const router = useRouter();

  const handleSelectCompany = ({ value }: { value: string }) => {
    setCookie(`@${data?.name}/selectedCompany`, value, {
      expires: addDays(new Date(), 7)
    });
    dispatch({ type: 'SET_SELECTED_COMPANY', payload: value });
  };

  const handleLogin = async ({
    token,
    user
  }: {
    token: string;
    user: Customer;
  }) => {
    const decoded = decodeToken(token);
    if (!decoded) return;
    setCookie(`@${data?.name}/authToken`, token, {
      expires: addDays(new Date(), 7)
    });
    setCookie(`@${data?.name}/user`, JSON.stringify(user), {
      expires: addDays(new Date(), 7)
    });
    dispatch({
      type: 'SET_USER',
      payload: user
    });
  };

  const logout = async () => {
    dispatch({ type: 'SET_USER', payload: undefined });

    try {
      await router.push('/login').then(() => {
        deleteCookie(`@${data?.name}/authToken`);
        deleteCookie(`@${data?.name}/user`);
      });
    } catch (e) {
      console.error('Navigation failed', e);
    }
  };

  const logoutWrapper = () => {
    logout().catch((err) => console.error('Logout failed:', err));
  };

  const handleSetUser = (user: Customer | null) => {
    dispatch({ type: 'SET_USER', payload: user });
  };

  useEffect(() => {
    const company = getCookie(`@${data?.name}/selectedCompany`);
    dispatch({ type: 'SET_SELECTED_COMPANY', payload: company || '' });
  }, [data]);

  const { refetch, isLoading } = useUser({
    options: {
      enabled: false,

      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        dispatch({ type: 'SET_USER', payload: data });
      }
    }
  });

  const token = useToken();

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  return (
    <AuthContext.Provider
      value={{
        refetchUserQuery: refetch,
        selectedCompany: state.selectedCompany,
        handleSelectCompany,
        user: state.user,
        logout: logoutWrapper,
        handleLogin,
        handleSetUser,
        isLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;
