import React from 'react'
import { useCarousel } from '../../../../utils/hooks/useCarousel'
import { Flex } from '../../../../components/Flex/Flex';

interface Props {
  datesInterval: Date[]
  selectedDate: Date
  handleSelectDate: (date: Date) => void
}

export const RoteiroPersonalizadoDiasCarrosel = ({ datesInterval, selectedDate, handleSelectDate }: Props) => {
  const { emblaRef } = useCarousel({ dragFree: true });



  return (
    <div
      ref={emblaRef}
      className="relative cursor-pointer flex-nowrap"
    >
      <Flex className=" gap-2 w-full">
        {datesInterval?.map((date, index) => {
          const selected = selectedDate.toLocaleDateString('pt-BR')
          const dateFormatted = date.toLocaleDateString('pt-BR')
          return (
            <Flex key={index} onClick={(e) => {
              e.preventDefault()
              handleSelectDate(date)
            }} className='rounded-[16px] min-w-[110px] h-8 px-4 items-center justify-center bg-neutral-pureOp10 text-neutral-pure1000 h6 data-[selected=true]:text-white data-[selected=true]:bg-[color:var(--bg-primary-pure)]' data-selected={selected === dateFormatted}>
              <p>{date.toLocaleDateString('pt-BR')}</p>
            </Flex>
          )
        })}
      </Flex>
    </div>
  )
}
