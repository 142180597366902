import { array, date, object, string } from 'yup';
import { TabOptions } from '../../interfaces/Fretamento/fretamento';

const idaSchema = object({
  origem: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataIda: date().required('É obrigatório inserir uma data de ida.'),
  destino: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataVolta: date().required('É obrigatório inserir uma data de volta.'),
  nome: string().required('É obrigatório inserir um nome.'),
  embarques: array().of(
    object({
      address: string().required(
        'É obrigatório inserir um endereço formatado.'
      ),
      city: string().required('É obrigatório inserir uma cidade.'),
      address_components: object({
        route: string().required('É obrigatório inserir um endereço.'),
        state: string().required('É obrigatório inserir um estado.'),
        state_short: string().required('É obrigatório inserir um estado.'),
        city: string().required('É obrigatório inserir uma cidade.'),
        district: string(),
        postal_code: string()
      }),
      lat: string().required('É obrigatório inserir a latitude.'),
      lng: string().required('É obrigatório inserir a longitude.')
    }).required()
  ),
  email: string()
    .email('E-mail inválido!')
    .required('É obrigatório inserir um e-mail válido.'),
  telefone: string().required('É obrigatório inserir um telefone válido.')
});
const idaFiltroSchema = object({
  origem: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataIda: date().required('É obrigatório inserir uma data de ida.'),
  destino: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataVolta: date().required('É obrigatório inserir uma data de volta.'),
  embarques: array().of(
    object({
      address: string().required(
        'É obrigatório inserir um endereço formatado.'
      ),
      city: string().required('É obrigatório inserir uma cidade.'),
      address_components: object({
        route: string().required('É obrigatório inserir um endereço.'),
        state: string().required('É obrigatório inserir um estado.'),
        state_short: string().required('É obrigatório inserir um estado.'),
        city: string().required('É obrigatório inserir uma cidade.'),
        district: string(),
        postal_code: string()
      }),
      lat: string().required('É obrigatório inserir a latitude.'),
      lng: string().required('É obrigatório inserir a longitude.')
    }).required()
  )
});

const somenteIdaSchema = object({
  origem: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataIda: date().required('É obrigatório inserir uma data de ida.'),
  destino: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  nome: string().required('É obrigatório inserir um nome.'),
  embarques: array().of(
    object({
      address: string().required(
        'É obrigatório inserir um endereço formatado.'
      ),
      city: string().required('É obrigatório inserir uma cidade.'),
      address_components: object({
        route: string().required('É obrigatório inserir um endereço.'),
        state: string().required('É obrigatório inserir um estado.'),
        state_short: string().required('É obrigatório inserir um estado.'),
        city: string().required('É obrigatório inserir uma cidade.'),
        district: string(),
        postal_code: string()
      }),
      lat: string().required('É obrigatório inserir a latitude.'),
      lng: string().required('É obrigatório inserir a longitude.')
    }).required()
  ),
  email: string()
    .email('E-mail inválido!')
    .required('É obrigatório inserir um e-mail válido.'),
  telefone: string().required('É obrigatório inserir um telefone válido.')
});

const somenteIdaFiltroSchema = object({
  origem: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataIda: date().required('É obrigatório inserir uma data de ida.'),
  destino: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  embarques: array().of(
    object({
      address: string().required(
        'É obrigatório inserir um endereço formatado.'
      ),
      city: string().required('É obrigatório inserir uma cidade.'),
      address_components: object({
        route: string().required('É obrigatório inserir um endereço.'),
        state: string().required('É obrigatório inserir um estado.'),
        state_short: string().required('É obrigatório inserir um estado.'),
        city: string().required('É obrigatório inserir uma cidade.'),
        district: string(),
        postal_code: string()
      }),
      lat: string().required('É obrigatório inserir a latitude.'),
      lng: string().required('É obrigatório inserir a longitude.')
    }).required()
  )
});

const porHoraSchema = object({
  embarques: array().of(
    object({
      address: string().required(
        'É obrigatório inserir um endereço formatado.'
      ),
      city: string().required('É obrigatório inserir uma cidade.'),
      address_components: object({
        route: string().required('É obrigatório inserir um endereço.'),
        state: string().required('É obrigatório inserir um estado.'),
        state_short: string().required('É obrigatório inserir um estado.'),
        city: string().required('É obrigatório inserir uma cidade.'),
        district: string(),
        postal_code: string()
      }),
      lat: string().required('É obrigatório inserir a latitude.'),
      lng: string().required('É obrigatório inserir a longitude.')
    }).required()
  ),
  origem: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataIda: date().required('É obrigatório inserir uma data de ida.'),
  destino: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  disponibilidade: string().required(
    'É obrigatório inserir a disponibilidade desejada.'
  ),
  nome: string().required('É obrigatório inserir um nome.'),
  email: string()
    .email('E-mail inválido!')
    .required('É obrigatório inserir um e-mail válido.'),
  telefone: string().required('É obrigatório inserir um telefone válido.')
});

const porHoraFiltroSchema = object({
  embarques: array().of(
    object({
      address: string().required(
        'É obrigatório inserir um endereço formatado.'
      ),
      city: string().required('É obrigatório inserir uma cidade.'),
      address_components: object({
        route: string().required('É obrigatório inserir um endereço.'),
        state: string().required('É obrigatório inserir um estado.'),
        state_short: string().required('É obrigatório inserir um estado.'),
        city: string().required('É obrigatório inserir uma cidade.'),
        district: string(),
        postal_code: string()
      }),
      lat: string().required('É obrigatório inserir a latitude.'),
      lng: string().required('É obrigatório inserir a longitude.')
    }).required()
  ),
  origem: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  dataIda: date().required('É obrigatório inserir uma data de ida.'),
  destino: object({
    address: string().required('É obrigatório inserir um endereço formatado.'),
    city: string().required('É obrigatório inserir uma cidade.'),
    address_components: object({
      route: string().required('É obrigatório inserir um endereço.'),
      state: string().required('É obrigatório inserir um estado.'),
      state_short: string().required('É obrigatório inserir um estado.'),
      city: string().required('É obrigatório inserir uma cidade.'),
      district: string(),
      postal_code: string()
    }),
    lat: string().required('É obrigatório inserir a latitude.'),
    lng: string().required('É obrigatório inserir a longitude.')
  }).required(),
  disponibilidade: string().required(
    'É obrigatório inserir a disponibilidade desejada.'
  )
});

const personalizadoSchema = object({
  nome: string().required('É obrigatório inserir um nome.'),
  email: string()
    .email('E-mail inválido!')
    .required('É obrigatório inserir um e-mail válido.'),
  telefone: string().required('É obrigatório inserir um telefone válido.')
});

export const handleSelectedSchema = (selectedSchema: TabOptions) => {
  return {
    roundtrip: idaSchema,
    onewaytrip: somenteIdaSchema,
    customtrip: personalizadoSchema
  }[selectedSchema || 'roundtrip'];
};

export const handleFiltroSelectedSchema = (selectedSchema: TabOptions) => {
  return {
    roundtrip: idaFiltroSchema,
    onewaytrip: somenteIdaFiltroSchema,
    customtrip: object()
  }[selectedSchema || 'roundtrip'];
};
