import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from '../AuthProvider/AuthContext';
import useCheckAuth from '../../utils/hooks/useAuth';
import { useTenantDataProvider } from '../TenantProvider/TenantProvider';
import {
  pagesNotAllowedLogged,
  pagesNotAllowedWithoutLogin
} from '../../utils/constants/routes';
import { toast } from 'react-toastify';

export const CookieAuthenticator = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { data } = useTenantDataProvider();
  const { isLoading } = useCheckAuth({ data });
  const { user, isLoading: isLoadingUser } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (user && pagesNotAllowedLogged.includes(router.route)) {
        try {
          return await router.push('/');
        } catch (e) {
          console.error('Navigation failed', e);
        }
      }
      if (
        !user &&
        !isLoading &&
        !isLoadingUser &&
        pagesNotAllowedWithoutLogin.includes(router.route)
      ) {
        try {
          toast.error('Você precisa estar conectado para acessar essa página');
          return await router.push('/login');
        } catch (e) {
          console.error('Navigation failed', e);
        }
      }
    };
    checkAndRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user, router, data]);

  return <>{children}</>;
};
