import { useState, useCallback } from "react";

const useStateHandler = <T>(x: T) => {
  const [state, setState] = useState<T>(x);

  const handleState = useCallback((newState: T) => {
    setState(newState);
  }, []);

  return {
    state,
    handleState,
  };
};

export default useStateHandler;
