import React from 'react';
import { Flex } from '../../../../components/Flex/Flex';
import { useRoteiro } from '../../../../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
import { RoteiroPersonalizadoResumoItem } from './RoteiroPersonalizadoResumoItem';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  handleStep: () => void;
}

export const RoteiroPersonalizadoResumo = ({
  startDate,
  endDate,
  handleStep
}: Props) => {
  const startDateFormatted =
    startDate?.toLocaleDateString('pt-BR') || 'DD/MM/AAAA';
  const endDateFormatted = endDate?.toLocaleDateString('pt-BR') || 'DD/MM/AAAA';
  const { intervaloDias } = useRoteiro();
  return (
    <Flex className="justify-between bg-neutral-pure100  flex-col w-full h-full lg:w-[40%] overflow-y-auto gap-4">
      <Flex className="gap-4 flex-col p-6">
        <Flex className="gap-1 flex-col ">
          <p className="h5 text-neutral-pure1000">Resumo do roteiro</p>
          <Flex className="gap-1 items-center">
            <p className="bodysm text-neutral-pure700">{startDateFormatted}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <g clipPath="url(#clip0_353_16410)">
                <path
                  d="M12 10.5H6"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 13L12.5 10.5L10 8"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_353_16410">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <p className="bodysm text-neutral-pure700">{endDateFormatted}</p>
          </Flex>
        </Flex>
        <Flex className="flex-col gap-4 overflow-y-auto">
          {intervaloDias.map((dia, index) => {
            if (!dia.vaiUsarVeiculoNoDia) return null;
            return (
              <RoteiroPersonalizadoResumoItem
                destinos={dia.destinos}
                dia={dia.data}
                numeroDia={index + 1}
                key={index}
              />
            );
          })}
        </Flex>
      </Flex>
      <Flex className="p-6 lg:hidden bg-white items-center justify-between gap-2 lg:h-16 min-h-16 border-t-[1px] border-neutral-pureOp10">
        <button
          type="button"
          onClick={() => handleStep()}
          className="h-10 rounded-[6px] bg-white border-neutral-pureOp10 px-6 border-[1px] text-neutral-pure1000 h6bold"
        >
          Voltar
        </button>
        <button
          type="submit"
          className="h-10 rounded-[4px] bg-[color:var(--bg-primary-pure)] h6bold text-white px-6"
        >
          Pesquisar
        </button>
      </Flex>
    </Flex>
  );
};
