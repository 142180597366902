import { Customer } from '../../../interfaces/Auth/auth';
import { api } from '../../axios';

export const getUser = async ({
  token
}: {
  token?: string;
}): Promise<Customer> => {
  const response = await api.get<Customer>(`/user`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
};
