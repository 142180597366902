import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch
} from 'react';
import { Destino, Dia } from '../../interfaces/Fretamento/fretamento';
import { generateDateRange } from '../../utils/date';
import useStateHandler from '../../utils/hooks/useStateHandler';
import { RoteiroPersonalizadoModal } from '../../features/Trajeto/RoteiroPersonalizado/RoteiroPersonalizadoModal';

type RoteiroState = {
  startDate: Date | null;
  endDate: Date | null;
  intervaloDias: Dia[];
};

type RoteiroAction =
  | { type: 'INICIALIZAR_ROTEIRO'; payload: { startDate: Date; endDate: Date } }
  | {
      type: 'ADICIONAR_DESTINO';
      payload: { data: string; destinos: Destino[] };
    }
  | { type: 'REMOVER_DESTINO'; payload: { data: string; horarioSaida: string } }
  | { type: 'REINICIAR_ROTEIRO' }
  | {
      type: 'ATUALIZAR_USO_VEICULO';
      payload: { data: string; vaiUsarVeiculoNoDia: boolean };
    }
  | {
      type: 'SUBSTITUIR_ROTEIRO';
      payload: RoteiroState;
    };

type RoteiroContextType = RoteiroState & {
  dispatch: Dispatch<RoteiroAction>;
  showModal: boolean;
  handleShowModal: (newState: boolean) => void;
};

const initialState: RoteiroState = {
  startDate: null,
  endDate: null,
  intervaloDias: []
};

function roteiroReducer(
  state: RoteiroState,
  action: RoteiroAction
): RoteiroState {
  switch (action.type) {
    case 'INICIALIZAR_ROTEIRO':
      const { startDate, endDate } = action.payload;
      const intervaloDias = generateDateRange(startDate, endDate);
      return { ...state, startDate, endDate, intervaloDias };

    case 'ADICIONAR_DESTINO':
      return {
        ...state,
        intervaloDias: state.intervaloDias.map((dia) =>
          dia.data === action.payload.data
            ? { ...dia, destinos: action.payload.destinos }
            : dia
        )
      };
    case 'REMOVER_DESTINO':
      return {
        ...state,
        intervaloDias: state.intervaloDias.map((dia) =>
          dia.data === action.payload.data
            ? {
                ...dia,
                destinos: dia.destinos.filter(
                  (destino) =>
                    destino.horarioSaida !== action.payload.horarioSaida
                )
              }
            : dia
        )
      };
    case 'REINICIAR_ROTEIRO':
      return initialState; // Resets the state to its initial value
    case 'ATUALIZAR_USO_VEICULO':
      return {
        ...state,
        intervaloDias: state.intervaloDias.map((dia) =>
          dia.data === action.payload.data
            ? {
                ...dia,
                vaiUsarVeiculoNoDia: action.payload.vaiUsarVeiculoNoDia
              }
            : dia
        )
      };
    case 'SUBSTITUIR_ROTEIRO':
      return action.payload;
    default:
      return state;
  }
}

const RoteiroContext = createContext<RoteiroContextType | undefined>(undefined);

type RoteiroProviderProps = {
  children: ReactNode;
};

export const RoteiroProvider = ({ children }: RoteiroProviderProps) => {
  const [state, dispatch] = useReducer(roteiroReducer, initialState);
  const { state: showModal, handleState: handleShowModal } =
    useStateHandler(false);

  return (
    <RoteiroContext.Provider
      value={{ ...state, dispatch, showModal, handleShowModal }}
    >
      <RoteiroPersonalizadoModal
        showModal={showModal}
        handleClose={() => handleShowModal(false)}
      />
      {children}
    </RoteiroContext.Provider>
  );
};

export const useRoteiro = () => {
  const context = useContext(RoteiroContext);
  if (context === undefined) {
    throw new Error('useRoteiro deve ser usado dentro de um RoteiroProvider');
  }
  return context;
};
