//@ts-nocheck
import React, { useEffect } from 'react';
import { Flex } from '../../../../../components/Flex/Flex';
import { useRoteiro } from '../../../../../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
import {
  RoteiroPersonalizadoDestino,
  RoteiroPersonalizadoHorario,
  RoteiroPersonalizadoOrigem
} from './RoteiroPersonalizadoDiaInputs';
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  Controller
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType } from 'yup';
import { schema } from './roteiroPersonalizadoSchema';
import Icons from '../../../../../../public/assets/icons';
import {
  Destino,
  RoteiroPersonalizadoSteps
} from '../../../../../interfaces/Fretamento/fretamento';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { toast } from 'react-toastify';

type IFormValues = InferType<typeof schema>;

interface Props {
  dia: Date | null;
  handleStep: (step: RoteiroPersonalizadoSteps) => void;
  handleSelectDate: (date: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
}

export const RoteiroPersonalizadoDia = ({
  dia,
  handleSelectDate,
  startDate,
  endDate,
  handleStep
}: Props) => {
  const { intervaloDias, dispatch } = useRoteiro();

  const diaFormatted = dia?.toLocaleDateString('pt-BR');
  const currentDia = intervaloDias.find((d) => d.data === diaFormatted);

  const handlePreviousDate = () => {
    if (!dia || !startDate) return;

    const previousDay = new Date(dia);
    previousDay.setDate(dia.getDate() - 1);

    if (previousDay < startDate) {
      handleStep('data');
    } else {
      handleSelectDate(previousDay);
    }
  };

  const handleNextDate: SubmitHandler<IFormValues> = (data) => {
    if (!dia || !endDate || !diaFormatted) return;

    const nextDay = new Date(dia);
    nextDay.setDate(dia.getDate() + 1);

    dispatch({
      type: 'ADICIONAR_DESTINO',
      payload: {
        data: diaFormatted,
        destinos: (data.destinos as unknown as Destino[]) || []
      }
    });

    if (nextDay > endDate) {
      handleStep('info');
    } else {
      handleSelectDate(nextDay);
    }
  };

  const defaultValues = {
    destinos: currentDia?.destinos.map((destino) => ({
      horarioSaida: destino.horarioSaida,
      lugarSaida: destino.lugarSaida,
      lugarDestino: destino.lugarDestino,
      pontosEmbarque: destino.pontosEmbarque
    }))
  };

  const defaultValue = {
    horarioSaida: '',
    lugarSaida: {},
    lugarDestino: {},
    pontosEmbarque: []
  };

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors }
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { fields, append, remove } = useFieldArray<IFormValues>({
    control,
    name: 'destinos'
  });

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    handleNextDate(data);
  };

  useEffect(() => {
    reset({
      destinos: currentDia?.destinos,
      vaiUsarVeiculoNoDia: currentDia?.vaiUsarVeiculoNoDia
    });

    setValue('vaiUsarVeiculoNoDia', currentDia?.vaiUsarVeiculoNoDia);
    setValue(
      'destinos',
      currentDia?.destinos.map((destino) => ({
        horarioSaida: new Date(destino.horarioSaida) as unknown as string,
        lugarSaida: destino.lugarSaida,
        lugarDestino: destino.lugarDestino,
        pontosEmbarque: destino.pontosEmbarque
      }))
    );
  }, [dia, currentDia?.vaiUsarVeiculoNoDia]);

  useEffect(() => {
    return () => {
      reset();
      handleSelectDate(null);
    };
  }, []);

  return (
    <Flex className="flex-col gap-4  overflow-y-auto h-full">
      <Flex className="w-full gap-2 items-center px-5">
        <p className="h6 text-neutral-pure1000">
          Você vai usar o veículo neste dia?
        </p>
        <Flex className="items-center gap-2">
          <p className="text-neutral-pure700 bodysm">Não</p>
          <input
            type="checkbox"
            className="[--tglbg:white] toggle-primary toggle"
            checked={currentDia?.vaiUsarVeiculoNoDia}
            onChange={() =>
              dispatch({
                type: 'ATUALIZAR_USO_VEICULO',
                payload: {
                  data: diaFormatted || '',
                  vaiUsarVeiculoNoDia: !currentDia?.vaiUsarVeiculoNoDia
                }
              })
            }
          />
          <p className="text-neutral-pure700 bodysm">Sim</p>
        </Flex>
      </Flex>
      <form
        className="h-full overflow-y-auto"
        onSubmit={(...args) => void handleSubmit(onSubmit)(...args)}
      >
        <Flex
          className={`flex-col h-full ${currentDia?.vaiUsarVeiculoNoDia ? 'justify-between' : 'justify-end'} gap-2`}
        >
          {currentDia?.vaiUsarVeiculoNoDia && (
            <Flex className="flex-col gap-2 overflow-y-auto px-5">
              {fields.map((destinoField, index) => {
                if (index > 0) {
                  setValue(
                    `destinos.${index}.lugarSaida`,
                    fields[index - 1].lugarDestino
                  );
                }

                if (index === 0) {
                  const previousDateIndex =
                    intervaloDias.findIndex((d) => d.data === currentDia.data) -
                    1;

                  if (currentDia.data !== intervaloDias[0].data) {
                    console.log(
                      intervaloDias[previousDateIndex]?.destinos[
                        intervaloDias[previousDateIndex]?.destinos.length - 1
                      ]?.lugarDestino
                    );
                    setValue(
                      `destinos.0.lugarSaida`,
                      intervaloDias[previousDateIndex]?.destinos[
                        intervaloDias[previousDateIndex]?.destinos.length - 1
                      ]?.lugarDestino
                    );
                  }
                }

                return (
                  <Flex
                    className="border-neutral-pureOp10 border-[1px] rounded-[6px] flex-col lg:flex-row"
                    key={destinoField.id}
                  >
                    <Flex className="p-2 flex-col gap-3 justify-between w-full lg:w-[40%]">
                      <RoteiroPersonalizadoHorario
                        control={control}
                        errors={errors}
                        name={`destinos.${index}.horarioSaida`}
                      />
                      <input
                        type="hidden"
                        {...register(`destinos.${index}.index`)}
                        value={index}
                      />
                      {index > 0 && (
                        <Flex
                          onClick={() => remove(index)}
                          className="rounded-[6px] gap-2 cursor-pointer items-center"
                        >
                          <Icons.RemoveEmbarque className="text-[color:var(--text-primary-pure)] min-w-12 w-12 h-12 cursor-pointer" />
                          <p className="h6 text-error">Remover destino</p>
                        </Flex>
                      )}
                    </Flex>
                    <div className="divider divider-horizontal m-0 p-0 w-[1px]" />
                    <Flex className="p-2 flex-col w-full lg:w-[60%] gap-2">
                      {index === 0 && (
                        <RoteiroPersonalizadoOrigem
                          control={control}
                          errors={errors}
                          name={`destinos.${index}.lugarSaida`}
                        />
                      )}
                      <RoteiroPersonalizadoDestino
                        control={control}
                        errors={errors}
                        name={`destinos.${index}.lugarDestino`}
                      />
                      <Controller
                        control={control}
                        name={`destinos.${index}.pontosEmbarque`}
                        render={({ field: customField }) => {
                          // eslint-disable-next-line react-hooks/rules-of-hooks
                          const pontosEmbarqueFields = useFieldArray({
                            control,
                            name: `destinos.${index}.pontosEmbarque` as any
                          });

                          return (
                            <Flex className="flex-col gap-2">
                              {pontosEmbarqueFields.fields.map(
                                (field, pIndex) => (
                                  <Flex
                                    key={field.id}
                                    className="relative h-14 w-full border-[1px] justify-center border-neutral-pureOp10 rounded-[6px] px-[10px] flex-col gap-1"
                                  >
                                    <p className="h6 text-neutral-pure700">
                                      Ponto de embarque ou desembarque
                                    </p>
                                    <Flex className="items-center gap-[6px]">
                                      <ReactGoogleAutocomplete
                                        key={field.id}
                                        {...register(
                                          `destinos.${index}.pontosEmbarque.${pIndex}` as const
                                        )}
                                        options={{
                                          types: ['address'],
                                          componentRestrictions: {
                                            country: 'br'
                                          }
                                        }}
                                        apiKey={
                                          process.env
                                            .NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
                                        }
                                        className="bodysm text-neutral-pure1000 font-[500] w-[80%]"
                                        {...field}
                                        value={
                                          customField?.value?.[pIndex]?.address
                                        }
                                        defaultValue={
                                          control['_formValues']['destinos'][
                                            index
                                          ]['pontosEmbarque'][pIndex]
                                        }
                                        onPlaceSelected={(place) => {
                                          if (!place) return;
                                          const addressComponents =
                                            place.address_components;
                                          const getAddressComponent = (
                                            type,
                                            long = true
                                          ) =>
                                            long
                                              ? addressComponents.find(
                                                  (component) =>
                                                    component.types.includes(
                                                      type
                                                    )
                                                )?.long_name
                                              : addressComponents.find(
                                                  (component) =>
                                                    component.types.includes(
                                                      type
                                                    )
                                                )?.short_name;
                                          pontosEmbarqueFields.update(pIndex, {
                                            name: getAddressComponent('route'),
                                            address: place.formatted_address,
                                            city: getAddressComponent(
                                              'administrative_area_level_2'
                                            ),
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                            address_components: {
                                              route:
                                                getAddressComponent('route'),
                                              state: getAddressComponent(
                                                'administrative_area_level_1'
                                              ),
                                              state_short: getAddressComponent(
                                                'administrative_area_level_1',
                                                false
                                              ),
                                              city: getAddressComponent(
                                                'administrative_area_level_2'
                                              ),
                                              district: getAddressComponent(
                                                'sublocality_level_1'
                                              ),
                                              postal_code:
                                                getAddressComponent(
                                                  'postal_code'
                                                )
                                            }
                                          });
                                        }}
                                      />
                                    </Flex>
                                    <Icons.RemoveEmbarque
                                      className="text-[color:var(--text-primary-pure)] hidden lg:block min-w-12 w-12 h-12 absolute right-[3px] top-[3px] cursor-pointer"
                                      onClick={() =>
                                        pontosEmbarqueFields.remove(pIndex)
                                      }
                                    />
                                    <Icons.RemoveEmbarqueSmall
                                      className="text-[color:var(--text-primary-pure)] lg:hidden min-w-8 w-8 h-8  absolute right-2 top-3 cursor-pointer"
                                      onClick={() =>
                                        pontosEmbarqueFields.remove(pIndex)
                                      }
                                    />
                                  </Flex>
                                )
                              )}
                              <Flex
                                onClick={() =>
                                  pontosEmbarqueFields.append('' as any)
                                }
                                className="w-full border-[1px] border-neutral-pureOp10 cursor-pointer rounded-[6px] h-10 px-3 items-center gap-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.4"
                                    d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M7.5 10H12.5"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10 7.5V12.5"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <p className="h6 text-[color:var(--text-primary-pure)]">
                                  Ponto de embarque ou desembarque
                                </p>
                              </Flex>
                            </Flex>
                          );
                        }}
                      />
                    </Flex>
                  </Flex>
                );
              })}
              <Flex
                onClick={() => append(defaultValue)}
                className="min-h-10 w-fit border-[1px] border-neutral-pureOp10 cursor-pointer rounded-[6px] h-10 px-3 items-center gap-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    opacity="0.4"
                    d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 10H12.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 7.5V12.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="h6 text-[color:var(--text-primary-pure)]">
                  Adicionar novo destino
                </p>
              </Flex>
            </Flex>
          )}
          <Flex className="p-6  items-center justify-between gap-2 lg:h-16 min-h-16 border-t-[1px] border-neutral-pureOp10">
            <button
              type="button"
              onClick={() => handlePreviousDate()}
              className="h-10 rounded-[6px] bg-white border-neutral-pureOp10 px-6 border-[1px] text-neutral-pure1000 h6bold"
            >
              Voltar
            </button>
            <button
              type="submit"
              disabled={fields.length === 0 && currentDia?.vaiUsarVeiculoNoDia}
              className="h-10 rounded-[4px] bg-[color:var(--bg-primary-pure)] h6bold text-white px-6"
            >
              {dia === endDate ? 'Buscar' : 'Próxima data'}
            </button>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};
