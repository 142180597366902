//@ts-nocheck
import React from 'react';
import { Flex } from '../../../../../components/Flex/Flex';
import {
  Controller,
  FieldValues,
  Control,
  Path,
  FieldErrors
} from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR);
import ReactGoogleAutocomplete from 'react-google-autocomplete';

interface Props<T extends FieldValues> {
  icon: React.ReactNode;
  title: string;
  children: (field: {
    onChange: (...event: any[]) => void;
    onBlur: () => void;
    value: any;
    ref: React.Ref<any>;
    name: string;
  }) => React.ReactNode;
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
  type?: string;
  errors: FieldErrors<T>;
  label?: string;
  className?: string;
}

export const RoteiroPersonalizadoDiaInput = <T extends FieldValues>({
  children,
  icon,
  title,
  control,
  name
}: Props<T>) => {
  return (
    <Flex className="h-14 w-full border-[1px] justify-center border-neutral-pureOp10 rounded-[6px] px-[10px] flex-col gap-1">
      <p className="h6 text-neutral-pure700">{title}</p>
      <Flex className="items-center gap-[6px] text-[color:var(--text-primary-pure)]">
        {icon}
        <Controller
          control={control}
          name={name}
          render={({ field }) => children(field) as JSX.Element}
        />
      </Flex>
    </Flex>
  );
};

interface PersonalizedInputProps<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
  name: Path<T>;
}

export const RoteiroPersonalizadoHorario = <T extends FieldValues>({
  control,
  errors,
  name
}: PersonalizedInputProps<T>) => (
  <RoteiroPersonalizadoDiaInput
    name={name}
    control={control}
    errors={errors}
    icon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <g clipPath="url(#clip0_353_16358)">
          <path
            d="M2.5 10.5C2.5 11.4849 2.69399 12.4602 3.0709 13.3701C3.44781 14.2801 4.00026 15.1069 4.6967 15.8033C5.39314 16.4997 6.21993 17.0522 7.12987 17.4291C8.03982 17.806 9.01509 18 10 18C10.9849 18 11.9602 17.806 12.8701 17.4291C13.7801 17.0522 14.6069 16.4997 15.3033 15.8033C15.9997 15.1069 16.5522 14.2801 16.9291 13.3701C17.306 12.4602 17.5 11.4849 17.5 10.5C17.5 9.51509 17.306 8.53982 16.9291 7.62987C16.5522 6.71993 15.9997 5.89314 15.3033 5.1967C14.6069 4.50026 13.7801 3.94781 12.8701 3.5709C11.9602 3.19399 10.9849 3 10 3C9.01509 3 8.03982 3.19399 7.12987 3.5709C6.21993 3.94781 5.39314 4.50026 4.6967 5.1967C4.00026 5.89314 3.44781 6.71993 3.0709 7.62987C2.69399 8.53982 2.5 9.51509 2.5 10.5Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.4"
            d="M10 10.5H12.9167"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.4"
            d="M10 6.3335V10.5002"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_353_16358">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    }
    title="Horário"
  >
    {({ onChange, value }) => (
      <DatePicker
        selected={value}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        placeholderText="Horário de saída"
        timeCaption="Horário"
        dateFormat="HH:mm"
        locale="pt-BR"
        className="bodysm text-neutral-pure1000 font-[500] dark:bg-white dark:text-neutral-pure1000"
      />
    )}
  </RoteiroPersonalizadoDiaInput>
);

export const RoteiroPersonalizadoOrigem = <T extends FieldValues>({
  control,
  errors,
  name
}: PersonalizedInputProps<T>) => (
  <RoteiroPersonalizadoDiaInput
    name={name}
    control={control}
    errors={errors}
    icon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <g clipPath="url(#clip0_353_16368)">
          <path
            opacity="0.4"
            d="M7.5 9.6665C7.5 10.3295 7.76339 10.9654 8.23223 11.4343C8.70107 11.9031 9.33696 12.1665 10 12.1665C10.663 12.1665 11.2989 11.9031 11.7678 11.4343C12.2366 10.9654 12.5 10.3295 12.5 9.6665C12.5 9.00346 12.2366 8.36758 11.7678 7.89874C11.2989 7.4299 10.663 7.1665 10 7.1665C9.33696 7.1665 8.70107 7.4299 8.23223 7.89874C7.76339 8.36758 7.5 9.00346 7.5 9.6665Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.7138 14.3806L11.178 17.9164C10.8655 18.2286 10.4418 18.404 10.0001 18.404C9.55836 18.404 9.13469 18.2286 8.82218 17.9164L5.28551 14.3806C4.3532 13.4482 3.71829 12.2603 3.46108 10.9671C3.20388 9.67394 3.33592 8.33352 3.84051 7.11536C4.34511 5.89721 5.19959 4.85604 6.29591 4.12351C7.39223 3.39098 8.68115 3 9.99968 3C11.3182 3 12.6071 3.39098 13.7034 4.12351C14.7998 4.85604 15.6542 5.89721 16.1588 7.11536C16.6634 8.33352 16.7955 9.67394 16.5383 10.9671C16.2811 12.2603 15.6462 13.4482 14.7138 14.3806Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_353_16368">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    }
    title="Saindo de"
  >
    {(field) => (
      <div style={{ position: 'relative' }} className="w-full">
        <ReactGoogleAutocomplete
          options={{
            types: ['address'],
            componentRestrictions: { country: 'br' }
          }}
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
          className="bodysm text-neutral-pure1000  font-[500] dark:bg-white dark:text-neutral-pure1000 w-full"
          placeholder="Saindo de"
          {...field}
          value={field.value?.address || ''}
          onChange={(e) => {
            field.onChange({
              ...field.value,
              address: e.target.value
            });
          }}
          onPlaceSelected={(place) => {
            if (!place) return;
            const addressComponents = place.address_components;
            const getAddressComponent = (type, long = true) =>
              long
                ? addressComponents.find((component) =>
                    component.types.includes(type)
                  )?.long_name
                : addressComponents.find((component) =>
                    component.types.includes(type)
                  )?.short_name;

            field.onChange({
              ...field.value,
              name: getAddressComponent('route'),
              address: place.formatted_address,
              city: getAddressComponent('administrative_area_level_2'),
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              address_components: {
                route: getAddressComponent('route'),
                state: getAddressComponent('administrative_area_level_1'),
                state_short: getAddressComponent(
                  'administrative_area_level_1',
                  false
                ),
                city: getAddressComponent('administrative_area_level_2'),
                district: getAddressComponent('sublocality_level_1'),
                postal_code: getAddressComponent('postal_code')
              }
            });
          }}
        />
      </div>
    )}
  </RoteiroPersonalizadoDiaInput>
);

export const RoteiroPersonalizadoDestino = <T extends FieldValues>({
  control,
  errors,
  name
}: PersonalizedInputProps<T>) => (
  <RoteiroPersonalizadoDiaInput
    name={name}
    control={control}
    errors={errors}
    icon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <g clipPath="url(#clip0_353_16376)">
          <path
            d="M3.33301 14.6667C3.33301 15.1087 3.5086 15.5326 3.82116 15.8452C4.13372 16.1577 4.55765 16.3333 4.99967 16.3333C5.4417 16.3333 5.86563 16.1577 6.17819 15.8452C6.49075 15.5326 6.66634 15.1087 6.66634 14.6667C6.66634 14.2246 6.49075 13.8007 6.17819 13.4882C5.86563 13.1756 5.4417 13 4.99967 13C4.55765 13 4.13372 13.1756 3.82116 13.4882C3.5086 13.8007 3.33301 14.2246 3.33301 14.6667Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.333 14.6667C13.333 15.1087 13.5086 15.5326 13.8212 15.8452C14.1337 16.1577 14.5576 16.3333 14.9997 16.3333C15.4417 16.3333 15.8656 16.1577 16.1782 15.8452C16.4907 15.5326 16.6663 15.1087 16.6663 14.6667C16.6663 14.2246 16.4907 13.8007 16.1782 13.4882C15.8656 13.1756 15.4417 13 14.9997 13C14.5576 13 14.1337 13.1756 13.8212 13.4882C13.5086 13.8007 13.333 14.2246 13.333 14.6667Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.33366 14.6665H1.66699V5.49984C1.66699 5.27882 1.75479 5.06686 1.91107 4.91058C2.06735 4.7543 2.27931 4.6665 2.50033 4.6665H14.167C15.2721 4.6665 16.3319 5.28109 17.1133 6.37505C17.8947 7.46901 18.3337 8.95274 18.3337 10.4998M18.3337 10.4998V14.6665H16.667M18.3337 10.4998L14.584 10.4998L13.334 4.6665M13.3337 14.6665H6.66699"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.4"
            d="M1.66699 8.83316H14.167M5.83398 4.6665V8.83317M10.0005 4.6665V8.83317"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_353_16376">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    }
    title="Indo para"
  >
    {(field) => (
      <ReactGoogleAutocomplete
        options={{
          types: ['address'],
          componentRestrictions: { country: 'br' }
        }}
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
        className="bodysm text-neutral-pure1000 font-[500] dark:bg-white dark:text-neutral-pure1000 w-full"
        placeholder="Indo para"
        {...field}
        value={field.value?.address || ''}
        onChange={(e) => {
          field.onChange({
            ...field.value,
            address: e.target.value
          });
        }}
        onPlaceSelected={(place) => {
          if (!place) return;
          const addressComponents = place.address_components;
          const getAddressComponent = (type, long = true) =>
            long
              ? addressComponents.find((component) =>
                  component.types.includes(type)
                )?.long_name
              : addressComponents.find((component) =>
                  component.types.includes(type)
                )?.short_name;

          field.onChange({
            ...field.value,
            name: getAddressComponent('route'),
            address: place.formatted_address,
            city: getAddressComponent('administrative_area_level_2'),
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            address_components: {
              route: getAddressComponent('route'),
              state: getAddressComponent('administrative_area_level_1'),
              state_short: getAddressComponent(
                'administrative_area_level_1',
                false
              ),
              city: getAddressComponent('administrative_area_level_2'),
              district: getAddressComponent('sublocality_level_1'),
              postal_code: getAddressComponent('postal_code')
            }
          });
        }}
      />
    )}
  </RoteiroPersonalizadoDiaInput>
);
