import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import { Vehicle } from '../../interfaces/Fretamento/fretamento';
import useStateHandler from '../../utils/hooks/useStateHandler';
import { useAuth } from '../AuthProvider/AuthProvider';
import {
  CreateOrderBody,
  CreateOrderBodyItem
} from '../../http/mutations/orders/useCreateOrder';
import { Order, ReservaFinalizada } from '../../interfaces/Reserva/reserva';
import { SearchResponseData } from '../../http/mutations/search/useSaveSearch';

interface FinalizarReservaState {
  reserva: Partial<CreateOrderBody> | null;
  setReserva: Dispatch<SetStateAction<Partial<CreateOrderBody> | null>>;
  reservaFinalizada?: ReservaFinalizada;
  searchData?: SearchResponseData;
  handleSetSearchData: (data: SearchResponseData) => void;
  handleSetReservaFinalizada: (order: ReservaFinalizada) => void;
  handleSetReserva: (reserva: Partial<CreateOrderBody>) => void;
  handleSetReservaVehicles: (vehicles: Vehicle[]) => void;
  handleAddItemsToReserva: (items: CreateOrderBodyItem[]) => void;
  reservaVehicles: Vehicle[];
  setReservaVehicles: Dispatch<SetStateAction<Vehicle[]>>;
}

type FinalizarReservaContextType = FinalizarReservaState;

const FinalizarReservaContext = createContext<
  FinalizarReservaContextType | undefined
>(undefined);

type FinalizarReservaProviderProps = {
  children: ReactNode;
};

export const FinalizarReservaProvider = ({
  children
}: FinalizarReservaProviderProps) => {
  const { state: reservaFinalizada, handleState: handleSetReservaFinalizada } =
    useStateHandler<ReservaFinalizada | undefined>(undefined);
  const { user } = useAuth();
  const [reservaVehicles, setReservaVehicles] = useState<Vehicle[]>([]);
  const [reserva, setReserva] = useState<Partial<CreateOrderBody> | null>({
    customer_id: user?.id,
    user_id: user?.id,
    email: user?.email,
    cpf: user?.cnpj_cpf
  });

  const handleSetReserva = (reserva: Partial<CreateOrderBody>) => {
    setReserva(reserva);
  };

  const { state: searchData, handleState: handleSetSearchData } =
    useStateHandler<SearchResponseData | undefined>(undefined);

  const handleSetReservaVehicles = (vehicles: Vehicle[]) => {
    setReservaVehicles(vehicles);
  };

  const handleAddItemsToReserva = (items: CreateOrderBodyItem[]) => {
    return handleSetReserva({
      ...reserva,
      items: items
    });
  };

  return (
    <FinalizarReservaContext.Provider
      value={{
        searchData,
        handleSetSearchData,
        reservaFinalizada,
        handleSetReservaFinalizada,
        reserva,
        setReserva,
        handleSetReserva,
        setReservaVehicles,
        handleAddItemsToReserva,
        reservaVehicles,
        handleSetReservaVehicles
      }}
    >
      {children}
    </FinalizarReservaContext.Provider>
  );
};

export const useFinalizarReserva = () => {
  const context = useContext(FinalizarReservaContext);
  if (context === undefined) {
    throw new Error(
      'useFinalizarReserva deve ser usado dentro de um FinalizarReservaProvider'
    );
  }
  return context;
};
