import React from 'react';
import { Flex } from '../../../../components/Flex/Flex';
import { Destino } from '../../../../interfaces/Fretamento/fretamento';
import {
  RoteiroPersonalizadoResumoDestino,
  RoteiroPersonalizadoResumoOrigem
} from './RoteiroPersonalizadoResumoCards';

interface Props {
  dia: string;
  numeroDia: number;
  destinos: Destino[];
}

export const RoteiroPersonalizadoResumoItem = ({
  dia,
  numeroDia,
  destinos
}: Props) => {
  return (
    <Flex className="bg-white rounded-[6px] p-2 flex-col gap-2 lg:max-w-[248px]">
      <Flex className="items-center rounded-[6px] px-2 h-5 bg-neutral-pure300">
        <p className="h7bold text-neutral-pure800">
          {numeroDia}° dia - {dia}
        </p>
      </Flex>
      {destinos.map((destino, index) => (
        <Flex className="flex-col" key={destino.lugarSaida.address}>
          {index === 0 && (
            <RoteiroPersonalizadoResumoOrigem
              horarioSaída={destino.horarioSaida}
              origem={destino.lugarSaida}
              pontosEmbarque={destino.pontosEmbarque}
            />
          )}
          <RoteiroPersonalizadoResumoDestino
            destino={destino.lugarDestino}
            nextStop={
              index + 1 < destinos.length ? destinos[index + 1] : undefined
            }
          />
        </Flex>
      ))}
    </Flex>
  );
};
