function withIconStyles(WrappedIcon: React.FC<any>) {
  const StyledIcon = (props: React.ComponentProps<any>) => (
    <WrappedIcon width="24" height="24"  {...props} />
  );

  StyledIcon.displayName = `WithStyles(${getDisplayName(WrappedIcon)})`;
  return StyledIcon;
}

function getDisplayName(WrappedComponent: React.ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withIconStyles;
