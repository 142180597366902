import { useEffect } from 'react';
import useStateHandler from './useStateHandler';
import { Customer, TabOptions } from '../../interfaces/Fretamento/fretamento';
import { handleSelectedSchema } from '../../features/Trajeto/trajetoSchemas';
import { InferType } from 'yup';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useRouter } from 'next/router';
import { useCalculate } from '../../http/mutations/orders/useCalculate';
import { useBuscaContext } from '../../providers/BuscaProvider/BuscaProvider';
import { useAuth } from '../../providers/AuthProvider/AuthProvider';
import { toast } from 'react-toastify';
import { useSaveSearch } from '../../http/mutations/search/useSaveSearch';
import { useFinalizarReserva } from '../../providers/FinalizarReservaProvider/FinalizarReservaProvider';
import { useRoteiro } from '../../providers/RoteiroPersonalizadoProvider/RoteiroPersonalizadoContext';
import { useMutateLeadRegister } from '../../http/mutations/useLeadRegister';
import { setCookie } from 'cookies-next';
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';
import { addDays, subHours } from 'date-fns';

export function formatDateTime(dateString: string) {
  const date = subHours(new Date(dateString), 3);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return {
    formattedDate: `${year}/${month}/${day}`,
    formattedTime: `${hours}:${minutes}`
  };
}

export function toISO8601String(dateStr: any) {
  // Input validation (same as before)
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!dateRegex.test(dateStr)) {
    throw new Error('Invalid date format. Please use DD/MM/YYYY.');
  }

  // Split into day, month, year
  const [day, month, year] = dateStr.split('/');

  // Create a Date object (using UTC) and validate
  const date = new Date(Date.UTC(year, month - 1, day)); // Note: Month is 0-based in JS Dates
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date components.');
  }

  // Generate ISO 8601 string (with UTC timezone 'Z')
  return date.toISOString();
}

export function reformatDateString(dateStr: string) {
  // Check if date string matches the format DD/MM/YYYY
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!dateRegex.test(dateStr)) {
    throw new Error('Invalid date format. Please use DD/MM/YYYY.');
  }

  // Split the date string into day, month, and year
  const [day, month, year] = dateStr.split('/');

  // Validate the date components (optional, but recommended)
  const date = new Date(`${year}-${month}-${day}`); // Create a Date object for validation
  if (isNaN(date.getTime())) {
    // Check if the date is valid
    throw new Error('Invalid date components.');
  }

  // Reformat the date string
  return `${year}/${month}/${day}`;
}
interface Props {
  buttonHandler?: (data: any) => void;
  customTab?: TabOptions;
}

export const useFretamento = ({
  buttonHandler,
  customTab = 'roundtrip'
}: Props) => {
  const { state: selectedTab, handleState: handleSelectedTab } =
    useStateHandler<TabOptions>(customTab);
  const schema = handleSelectedSchema(selectedTab);

  const { user, handleSetUser } = useAuth();

  type IFormValues = InferType<typeof schema>;

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    clearErrors,
    resetField,
    watch,
    setValue,
    formState: { errors }
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: user?.name || '',
      email: user?.email || '',
      telefone: user?.tel || '',
      dataIda: new Date(),
      dataVolta: new Date()
    }
  });

  useEffect(() => {
    if (user) {
      setValue('nome', user.name || '');
      setValue('email', user.email || '');
      setValue('telefone', user.tel || '');
      setValue('dataIda', new Date());
      setValue('dataVolta', new Date());
    }
  }, [user, setValue]);

  const handleSelectTab = (tab: TabOptions) => {
    resetField('embarques');
    handleSelectedTab(tab);
  };

  const { fields, append, remove } = useFieldArray<IFormValues>({
    control,
    name: 'embarques'
  });

  const { mutateAsync } = useCalculate({});

  const { mutateAsync: saveSearch } = useSaveSearch({});

  const router = useRouter();

  const { handleSetBuscaResult, handleIsLoading, handleDePara, handleBusca } =
    useBuscaContext();

  const { handleSetSearchData } = useFinalizarReserva();

  const { intervaloDias } = useRoteiro();

  const { mutateAsync: leadRegister } = useMutateLeadRegister({});

  const { data: tenantData } = useTenantDataProvider();

  let customer: Customer;

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    handleIsLoading(true);
    if (!user) {
      await leadRegister({
        name: data.nome,
        email: data.email,
        tel: data.telefone
      })
        .then((response) => {
          handleSetUser(response.data.customer);
          customer = response.data.customer;
          setCookie(
            `@${tenantData?.name}/authToken`,
            response.data.access_token,
            {
              expires: addDays(new Date(), 7)
            }
          );
          setCookie(
            `@${tenantData?.name}/user`,
            JSON.stringify(response.data.customer),
            {
              expires: addDays(new Date(), 7)
            }
          );
        })
        .catch((error) => {
          toast.error(error?.response?.data.message);
          handleIsLoading(false);
        });
    }

    if (selectedTab !== 'customtrip') {
      const { dataIda, dataVolta, origem, destino, embarques } =
        data as unknown as any;
      return mutateAsync({
        customerId: user?.id || customer.id || 0,
        simpleTrip: {
          customerId: user?.id || customer.id || 0,
          stop_points: embarques,
          origin: {
            ...origem,
            date: dataIda ? formatDateTime(dataIda).formattedDate : undefined,
            time: dataIda ? formatDateTime(dataIda).formattedTime : undefined
          },
          destination: {
            ...destino,
            date: dataVolta
              ? formatDateTime(dataVolta).formattedDate
              : undefined,
            time: dataVolta
              ? formatDateTime(dataVolta).formattedTime
              : undefined
          },
          tripType: selectedTab,
          vehicle_type: 'all',
          vehicle_usages: []
        }
      })
        .then((response) => {
          handleBusca({
            dataIda: dataIda,
            dataVolta: dataVolta,
            origem: {
              ...origem,
              date: dataIda ? formatDateTime(dataIda).formattedDate : undefined,
              time: dataIda ? formatDateTime(dataIda).formattedTime : undefined
            },
            destino: {
              ...destino,
              date: dataVolta
                ? formatDateTime(dataVolta).formattedDate
                : undefined,
              time: dataVolta
                ? formatDateTime(dataVolta).formattedTime
                : undefined
            },
            embarques: embarques,
            type: selectedTab
          });
          handleDePara({
            de: `${origem.address_components.city}, ${origem.address_components.state_short}`,
            para: `${destino.address_components.city}, ${destino.address_components.state_short}`
          });
          handleSetBuscaResult(response.data);
          saveSearch({
            city_destination: destino.address_components.city,
            city_origin: origem.address_components.city,
            customer_id: user?.id || customer.id || 0,
            date_destination: formatDateTime(dataVolta).formattedDate,
            date_origin: formatDateTime(dataIda).formattedDate,
            district_destination: destino.address_components.district,
            district_origin: origem.address_components.district,
            route_destination: destino.address_components.route,
            route_origin: origem.address_components.route,
            state_destination: destino.address_components.state,
            state_origin: origem.address_components.state,
            type: selectedTab,
            vehicles: response.data.vehicles,
            extra_data: {
              metas: {
                ...response.data.metas
              } as unknown as any,
              driverExpenses: response.data.driverExpenses
            }
          })
            .then((searchResponse) => {
              handleSetSearchData(searchResponse.data);
              buttonHandler?.(data);

              router.push({ pathname: '/buscar' });
            })
            .catch((error) => {
              toast.error(error?.response?.data.message);
            })
            .finally(() => handleIsLoading(false));
        })
        .catch((error) => {
          toast.error(error?.response?.data.message);
        })
        .finally(() => handleIsLoading(false));
    }

    return mutateAsync({
      customerId: user?.id || customer.id || 0,
      customRouter: {
        customer_id: user?.id || customer.id || 0,
        initialDate: reformatDateString(intervaloDias[0].data),
        endDate: reformatDateString(
          intervaloDias[intervaloDias.length - 1].data
        ),
        days: intervaloDias.map((item) => ({
          active: item.vaiUsarVeiculoNoDia || false,
          date: item.data,
          routes: item.destinos.map((route) => ({
            date: item.data,
            time: formatDateTime(route.horarioSaida).formattedTime,
            origin: route.lugarSaida,
            destination: route.lugarDestino,
            stop_points: route.pontosEmbarque || ([] as any)
          }))
        })),
        disabledDates: {
          to: toISO8601String(intervaloDias[intervaloDias.length - 1].data)
        },
        hideDestination: false,
        status: true,
        step: intervaloDias.length - 1,
        tripDays: intervaloDias.length,
        routes: []
      }
    })
      .then((response) => {
        handleBusca({
          dataIda: intervaloDias[0].data,
          dataVolta: intervaloDias[intervaloDias.length - 1].data,
          origem: {
            ...intervaloDias[0].destinos[0].lugarSaida,
            date: intervaloDias[0].data
              ? formatDateTime(intervaloDias[0].data).formattedDate
              : undefined,
            time: intervaloDias[0].data
              ? formatDateTime(intervaloDias[0].data).formattedTime
              : undefined
          },
          destino: {
            ...intervaloDias[intervaloDias.length - 1].destinos[
              intervaloDias[intervaloDias.length - 1].destinos.length - 1
            ].lugarDestino,
            date: intervaloDias[intervaloDias.length - 1].data
              ? formatDateTime(intervaloDias[intervaloDias.length - 1].data)
                  .formattedDate
              : undefined,
            time: intervaloDias[intervaloDias.length - 1].data
              ? formatDateTime(intervaloDias[intervaloDias.length - 1].data)
                  .formattedTime
              : undefined
          },
          embarques: intervaloDias.map((item) =>
            item.destinos.flatMap((route) => route.pontosEmbarque || [])
          ) as any,
          type: selectedTab
        });
        handleDePara({
          de: `${intervaloDias[0].destinos[0].lugarSaida.address_components.city}, ${intervaloDias[0].destinos[0].lugarSaida.address_components.state_short}`,
          para: `${intervaloDias[intervaloDias.length - 1].destinos[intervaloDias[intervaloDias.length - 1].destinos.length - 1].lugarDestino.address_components.city}, ${intervaloDias[intervaloDias.length - 1].destinos[intervaloDias[intervaloDias.length - 1].destinos.length - 1].lugarDestino.address_components.state_short}`
        });
        handleSetBuscaResult(response.data);
        saveSearch({
          city_destination:
            intervaloDias[intervaloDias.length - 1].destinos[
              intervaloDias[intervaloDias.length - 1].destinos.length - 1
            ].lugarDestino.address_components.city,
          city_origin:
            intervaloDias[0].destinos[0].lugarSaida.address_components.city,
          customer_id: user?.id || customer.id || 0,
          date_destination: reformatDateString(
            intervaloDias[intervaloDias.length - 1].data
          ),
          date_origin: reformatDateString(intervaloDias[0].data),
          district_destination:
            intervaloDias[intervaloDias.length - 1].destinos[
              intervaloDias[intervaloDias.length - 1].destinos.length - 1
            ].lugarDestino.address_components.district,
          district_origin:
            intervaloDias[0].destinos[0].lugarSaida.address_components.district,
          route_destination:
            intervaloDias[intervaloDias.length - 1].destinos[
              intervaloDias[intervaloDias.length - 1].destinos.length - 1
            ].lugarDestino.address_components.route || ``,
          route_origin:
            intervaloDias[0].destinos[0].lugarSaida.address_components.route ||
            ``,
          state_destination:
            intervaloDias[intervaloDias.length - 1].destinos[
              intervaloDias[intervaloDias.length - 1].destinos.length - 1
            ].lugarDestino.address_components.state,
          state_origin:
            intervaloDias[0].destinos[0].lugarSaida.address_components.state,
          type: selectedTab,
          vehicles: response.data.vehicles,
          extra_data: {
            metas: {
              ...response.data.metas
            } as unknown as any,
            driverExpenses: response.data.driverExpenses
          }
        })
          .then((searchResponse) => {
            handleSetSearchData(searchResponse.data);
            buttonHandler?.(data);

            router.push({ pathname: '/buscar' });
          })
          .catch((error) => {
            toast.error(error?.response?.data.message);
          })
          .finally(() => handleIsLoading(false));
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
      })
      .finally(() => handleIsLoading(false));
  };

  useEffect(() => {
    const currentValues = getValues();
    const savedValues = {
      nome: currentValues.nome,
      email: currentValues.email,
      telefone: currentValues.telefone
    };

    reset({
      embarques: [],
      dataIda: undefined,
      dataVolta: undefined,
      origem: {},
      destino: {},
      ...savedValues
    });

    setValue('dataIda', new Date());
    setValue('dataVolta', new Date());
    setValue('origem', {
      address: '',
      lat: '',
      lng: '',
      address_components: {
        city: '',
        district: '',
        route: '',
        state: '',
        state_short: '',
        postal_code: ''
      },
      city: ''
    });
    setValue('destino', {
      address: '',
      lat: '',
      lng: '',
      address_components: {
        city: '',
        district: '',
        route: '',
        state: '',
        state_short: '',
        postal_code: ''
      },
      city: ''
    });
    clearErrors();
  }, [selectedTab]);

  return {
    selectedTab,
    handleSelectedTab,
    schema,
    watch,
    handleSubmit,
    reset,
    control,
    getValues,
    errors,
    handleSelectTab,
    fields,
    append,
    remove,
    onSubmit
  };
};
